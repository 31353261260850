import styled from 'styled-components'
import Cleave from 'cleave.js/react'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`

export const SubContainer = styled(Container)`
  width: 100%;
  padding: 0px;
`

export const RatingContainer = styled.div`
  display: flex;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 25px;
  padding: 0px 10px 0px 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border: 1px solid #EEEEEE;
  border-radius: 15px;
  background-color: #FFFFFF;
`

export const FormField = styled(Container)`
  flex: 1;
  width: 100%;
  justify-content: flex-end;
  margin: 0px 0px 5px;
  padding: 0px;
`

export const Image = styled.img`
  width: 80px;
  height: 80px;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 20px;
`

export const SmallImage = styled.img`
  width: 24px;
  height: 24px;
`

export const ProfessionalPicture = styled(Image)`
  width: 100px;
  height: 100px;
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`

export const FilterColumn = styled(Column)`
  padding: 0;
  width: 280px;

  @media (max-width: 479px) {
    width: 100%;
  }
`

export const Row = styled(Container)`
  width: 100%;
  padding: 0px;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
`

export const TextRow = styled(Row)`
  justify-content: flex-start;
  gap: 5px;
`

export const LogoRow = styled(Row)`
  gap: 5px;
  margin-bottom: 10px;
`

export const StepRow = styled.div`
  width: 360px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  position: relative;
  margin: 10px;
`

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
`

export const StepLabel = styled.p`
  color: #999999;
  font-size: 11px;
  margin: 0px;
`

export const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 99px;
  background-color: #252525;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StepCircle = styled(Circle)`
  z-index: 2;
  background-color: ${({ step, currentStep }) => (
    currentStep?.number > step?.number
      ? '#44CF6C'
      : currentStep?.number === step?.number ? '#FFC100' : '#252525'
  )};

  p {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 1px;
  }
`

export const StepLine = styled.span`
  display: inline-block;
  position: absolute;
  top: 14px;
  left: 35px;
  width: ${({ currentStep }) => currentStep * 73}px;
  height: 3px;
  z-index: 1;
  background-color: #44CF6C;
`

export const Form = styled.form`
  background-color: #F7F7F7;
  box-shadow: 0px 2px 10px -6px #252525;
  width: 400px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
  padding: 10px;

  @media (max-width: 420px) {
    width: 100%;
  }
`

export const ServicesGrid = styled.div`
  width: 1000px;
  display: grid;
  gap: 10px;
  justify-items: stretch;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-top: 15px;
  
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
  
  @media (max-width: 1023px) {
    width: 720px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    width: 460px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
`

export const TimeTablesGrid = styled.div`
  display: grid;
  gap: 10px;
  justify-items: stretch;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-top: 15px;

  @media (min-width: 1366px) {
    width: 50%;
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1365px) {
    width: 80%;
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 576px) {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
`

export const ProfessionalsGrid = styled.div`
  display: grid;
  gap: 5px;
  justify-items: stretch;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  margin-top: 15px;

  @media (min-width: 640px) {
    width: 600px;
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 639px) {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
`

export const InfoText = styled.p`
  font-size: 14px;
  text-align: center;
  line-height: 18px;
  margin: 0px;
`

export const Title = styled(InfoText)`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 7px;
`

export const SubTitle = styled(Title)`
  font-size: 14px;
  margin-bottom: 0px;
`

export const LinkText = styled.a`
  color: #35A7FF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  gap: 5px;

  &:hover {
    color: #2875E2;
  }
`

export const WarningText = styled(InfoText)`
  color: #FF3939;
  margin-top: 2px;
`

export const ErrorContainer = styled.div`
  padding: 9px;
  margin-top: 15px;
  width: 100%;
  border-radius: 15px;
  align-items: center;
  background-color: #FF3939;
`

export const Button = styled.button.attrs ({
  type: 'button'
})`
  background-color: #252525;
  color: #F7F7F7;
  height: 40px;
  padding: 0px 20px;
  border-radius: 15px;
  line-height: 16px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: none;

  &:hover {
    background-color: #ffC100;
    color: #FFFFFF;
  }
`

export const DangerButton = styled(Button)`
  background-color: #FF3939;

  &:hover {
    background-color: #FF2020;
    color: #FFFFFF;
  }
`

export const SmallButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-bottom: 10px;
  border: 0px;
  gap: 8px;

  &:hover {
    color: #FFC100;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  margin-top: 10px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;

  &::placeholder {
    color: #BBBBBB
  }

  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const MaskedInput = styled(Cleave)`
  width: 100%;
  height: 38px;
  margin-top: 10px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`
