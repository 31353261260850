import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`

export const LoadingContainer = styled.div`
  width: 120px;
  height: 9px;
  border: 1px solid #CCCCCC;
  border-radius: 99px;
`

export const LoadedIndicator = styled.div`
  width: ${({ totalValue, currentValue }) => `${(currentValue / totalValue) * 100}%`};
  height: 7px;
  background-color: ${({ totalValue, currentValue }) => {
    const percentage = (currentValue / totalValue) * 100
    return percentage >= 67 ? '#44CF6C' : (percentage < 34 ? '#FF3939' : '#FFC100')
  }};
  border-radius: 15px;
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 14px;
  text-align: center;
  margin-bottom: 1px;
`
