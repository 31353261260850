import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ selected }) => selected ? '#F7F7F7' : '#FFFFFF'};
  width: 100%;
  margin: 0px 0px 10px;
  box-shadow: 0px 2px 10px -6px #252525;
  text-decoration: none;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  padding: 7px 12px;

  &:hover {
    background-color: #F7F7F7;
  }
`

export const DataGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  padding-right: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const FormGrid = styled(DataGrid)`
  align-items: flex-end;
  margin: 6px 0px 3px;
  padding: 5px 0px 0px;
  border-top: 1px solid #F0F0F0;
  grid-template-columns: 2fr repeat(4, 1fr);
  cursor: auto;
`

export const ProductGrid = styled(FormGrid)`
  align-items: flex-start;
  grid-template-columns: 2fr repeat(3, 1fr);
  position: relative;
`

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const Image = styled.img`
  width: 20px;
  height: 20px;
`

export const InfoText = styled.p`
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin: 0px;
`

export const Label = styled.label`
  text-align: center;
  color: #000000;
  margin-bottom: 4px;
  line-height: 16px;

  &:hover {
    cursor: pointer;
  }
`

export const SubTitle = styled(InfoText)`
  font-weight: bold;
`

export const WarningText = styled(InfoText)`
  color: #FF3939;
`

export const TooltipLabel = styled.label`
  text-align: center;
  color: #FFFFFF;
  margin: 0px;

  &:hover {
    cursor: pointer;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  margin-top: 10px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;

  &::placeholder {
    color: #BBBBBB
  }

  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const SmallButton = styled.button.attrs ({
  type: 'button'
})`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  background-color: transparent;
  text-decoration: none;
  color: #FFC100;
  border: 0px;
  text-align: center;
  gap: 5px;
`

export const DangerButton = styled(SmallButton)`
  width: 50px;
  height: 38px;
  align-self: flex-start;
  background-color: #FF3939;
  color: #FFFFFF;
  border-radius: 15px;
  margin-top: 20px;

  &:hover {
    background-color: #FF2020;
    color: #FFFFFF;
  }
`
