import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const modalStyle = {
  overlay: {
    zIndex: 5
  },
  content: {
    width: 480,
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 15,
    boxShadow: '0px 2px 15px -8px #252525'
  }
}

export const Container = styled.div`
  max-height: 600px;
  background: #F7F7F7;
  border-radius: 15px;
  overflow: hidden;
  padding: 10px;
  justify-content: center;
  align-items: center;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 14px;
  text-align: center;
`

export const Title = styled(InfoText)`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`

export const SubTitle = styled(Title)`
  font-size: 14px;
`

export const LinkText = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 8px 10px;
  border-radius: 15px;
  color: #35A7FF;
  background-color: #FFFFFF;
  text-decoration: none;
  box-shadow: 0px 2px 10px -6px #252525;
  font-size: 14px;
  line-height: 16px;

  &:hover {
    color: #2875E2;
  }
`

export const CloseButton = styled.button`
  position: fixed;
  top: 3px;
  right: 10px;
  width: 30px;
  height: 38px;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`
