import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi, { apiUrl } from '../../services/cutileiApi'
import AuthService from '../../services/auth'
import TimeWindow from '../../services/timeWindow'
import Calendar from 'react-calendar'
import ReactTooltip from 'react-tooltip'
import { DateTime } from 'luxon'
import Navbar from '../../components/Navbar'
import Tutorial from '../../components/Tutorial'
import ClearableInput from '../../components/Inputs/ClearableInput'
import SchedulesTable from '../../components/SchedulesTable'
import CreateReservationModal from '../Modals/CreateReservationModal'
import ScheduleLinkModal from '../Modals/ScheduleLinkModal'
import ChangePictureModal from '../Modals/ChangePictureModal'
import InactiveBusinessModal from '../Modals/InactiveBusinessModal'
import { ReactComponent as Loading } from '../../icons/loading2.svg'
import { FaLink, FaUserPlus, FaStar } from 'react-icons/fa'
import { IoCalendarOutline } from 'react-icons/io5'
import io from 'socket.io-client'
import '../../styles/calendar.css'

import {
  Container,
  Row,
  Button,
  LinkButton,
  Header,
  ImageButton,
  Image,
  SubTitle,
  Label,
  TooltipLabel,
  SubContainer,
  LogoContainer,
  RatingContainer,
  CalendarContainer,
  Schedules
} from './styles'

function Agenda () {
  const [query, setQuery] = useState ('')
  const [selectedDate, setSelectedDate] = useState (new Date ())
  const [prevDate, setPrevDate] = useState (new Date ())
  const selectedDateRef = useRef (selectedDate)
  const scrollPosition = useRef (0)
  const [business, setBusiness] = useState (null)
  const [businessConfig, setBusinessConfig] = useState ([])
  const [workingDays, setWorkingDays] = useState ([])
  const [services, setServices] = useState ([])
  const [professionals, setProfessionals] = useState ([])
  const [schedules, setSchedules] = useState ([])
  const [timeWindows, setTimeWindows] = useState ([])
  const [timeInterval, setTimeInterval] = useState (30)
  const [loading, setLoading] = useState (true)
  const [validatingConfig, setValidatingConfig] = useState (true)
  const [showTutorial, setShowTutorial] = useState (false)
  const [showPictureDialog, setShowPictureDialog] = useState (false)
  const [showInactiveDialog, setShowInactiveDialog] = useState (false)
  const [showCreateReservationModal, setShowCreateReservationModal] = useState (false)
  const [showScheduleLinkModal, setShowScheduleLinkModal] = useState (false)
  const user = AuthService.getUser ()
  const token = AuthService.getToken ()
  const isAdmin = AuthService.isAdmin ()
  const isBusiness = AuthService.isBusiness ()
  const businessId = AuthService.getBusinessId ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    const newSocket = io (apiUrl)

    newSocket.on (`${businessId}_schedule_created`, schedule => handleCreateSchedule ([schedule]))
    newSocket.on (`${businessId}_reservation_created`, handleCreateReservations)
    newSocket.on (`${businessId}_schedule_updated`, handleEditSchedule)
    newSocket.on (`${businessId}_reservation_updated`, handleEditReservation)
    newSocket.on (`${businessId}_schedule_status_updated`, handleEditScheduleStatus)
    newSocket.on (`${businessId}_schedule_deleted`, handleDeleteSchedule)
    newSocket.on (`${businessId}_professional_removed`, handleRemoveProfessional)

    return () => {newSocket.disconnect ()}
  }, [])

  useEffect (() => {
    validateConfig ()
  }, [showTutorial])
  
  useEffect (() => {
    selectedDateRef.current = selectedDate
    if (businessConfig.isComplete) getData ()
  }, [selectedDate])
  
  useEffect (() => {
    if (loading) getTimeWindows ()
  }, [schedules])

  const validateConfig = async () => {
    try {
      const { data: business } = await cutileiApi.get (`/businesses/${user.id}`)
      const { data: businessConfig } = await cutileiApi.get (
        `/businesses/${businessId}/validate_config`, requestConfig
      )

      setBusiness (business)
      setBusinessConfig (businessConfig)
      setShowInactiveDialog (!businessConfig.isBusinessActive)
      if (businessConfig.isComplete) getData ()
      else setShowTutorial (true)
    } catch (error) {
      console.log (error)
    } finally {
      setValidatingConfig (false)
    }
  }

  const getData = async () => {
    const today = DateTime.local ().toISODate ()
    const selectedISODate = DateTime.fromJSDate (selectedDate).toISODate ()
    const prevISODate = DateTime.fromJSDate (prevDate).toISODate ()
    if (prevISODate === today) scrollPosition.current = window.scrollY

    setLoading (true)
    try {
      const { data: businessConfig } = await cutileiApi.get (
        `/businesses/${businessId}/validate_config`,
        requestConfig
      )

      setBusinessConfig (businessConfig)

      if (businessConfig.isComplete) {
        const { data: professionals } = await cutileiApi.get (
          `/businesses/${businessId}/professionals?access_level=service&status=active`,
          requestConfig
        )
  
        const { data: workingDays } = await cutileiApi.get (`/businesses/${businessId}/working_days`)

        const { data: services } = await cutileiApi.get (
          `/businesses/${businessId}/services`, requestConfig
        )
  
        const { data: schedules } = await cutileiApi.get (
          `/schedules?business_id=${businessId}&active=true&date=${selectedISODate}&page=1&per_page=999`,
          requestConfig
        )
  
        const { data: reservations } = await cutileiApi.get (
          `/businesses/${businessId}/schedule_reservations?date=${selectedISODate}`,
          requestConfig
        )
        
        setServices (services)
        setProfessionals (professionals)
        setWorkingDays (workingDays)
        setSchedules ([...schedules.data, ...reservations].sort (compareSchedules))
      } else setShowTutorial (true)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
      if (selectedISODate === today) window.scrollTo (0, scrollPosition.current) 
    }
  }

  const getTimeWindows = () => {
    const timeInterval = services.some (service => service.duration % 30 !== 0) ? 15 : 30
    const selectedWeekday = DateTime.fromJSDate (selectedDate).weekday
    const workingDay = workingDays.find (day => day.iso_number === selectedWeekday)
    const parseTime = time => DateTime.fromFormat (time, time.length > 5 ? 'HH:mm:ss' : 'H:mm')
    const formatTime = time => time.toFormat ('H:mm')

    setTimeInterval (timeInterval)

    if (workingDay) {
      const opening = workingDay.opening || workingDays[0].opening
      const closing = workingDay.closing || workingDays[0].closing
      const scheduleTimes = schedules.filter (s => s.validated !== undefined).map (s => ({
        start: parseTime (s.time).toMillis (),
        end: parseTime (s.time).plus ({minutes: s.duration}).toMillis ()
      }))

      const firstScheduleTime = DateTime.fromMillis (Math.min (...scheduleTimes.map (s => s.start)))
      const lastScheduleTime = DateTime.fromMillis (Math.max (...scheduleTimes.map (s => s.end)))

      const start = scheduleTimes.length > 0 && firstScheduleTime < parseTime (opening)
        ? formatTime (firstScheduleTime) : opening
      const end = scheduleTimes.length > 0 && lastScheduleTime >= parseTime (closing)
        ? formatTime (lastScheduleTime) : closing

      setTimeWindows (TimeWindow.getTimeWindows (start, end, false, timeInterval).map (t => t.value))
    }
  }

  const filterSchedulesByCustomer = schedules => {
    return query === '' ? schedules : schedules.filter (schedule => (
      schedule.validated !== undefined && (
        schedule.member?.name.toLowerCase ().includes (query.toLowerCase ()) ||
        schedule.customer?.name.toLowerCase ().includes (query.toLowerCase ())
      )
    ))
  }

  const compareSchedules = (a, b) => {
    const AstartTime = a.start_time || a.time
    const BstartTime = b.start_time || b.time

    return DateTime.fromISO (AstartTime).toMillis () - DateTime.fromISO (BstartTime).toMillis ()
  }

  const handleChangePicture = newPictureUrl => {
    AuthService.updateUser ({business: {...user, logo: newPictureUrl}})
    togglePictureDialog ()
  }

  const handleCreateSchedule = createdSchedules => {
    if (createdSchedules[0].date === DateTime.fromJSDate (selectedDateRef.current).toISODate ()) {
      setSchedules (prevSchedules => {
        const updatedSchedules = [...prevSchedules]
      
        createdSchedules.forEach (newSchedule => {
          if (!updatedSchedules.some (existingSchedule => existingSchedule.id === newSchedule.id))
            updatedSchedules.push (newSchedule)
        })
    
        return updatedSchedules.sort (compareSchedules)
      })
    }
  }

  const handleCreateReservations = reservations => {
    const currentDate = DateTime.fromJSDate (selectedDateRef.current).startOf ('day')

    setSchedules (prevSchedules => {
      const updatedSchedules = [...prevSchedules]
    
      reservations.forEach (reservation => {
        const startDate = DateTime.fromISO (reservation.start_date).startOf ('day')
        const endDate = DateTime.fromISO (reservation.end_date).startOf ('day')

        if (
          startDate <= currentDate && endDate >= currentDate
          && !updatedSchedules.some (existingSchedule => existingSchedule.id === reservation.id)
        ) updatedSchedules.push (reservation)
      })
  
      return updatedSchedules.sort (compareSchedules)
    })
  }

  const handleEditReservation = reservation => {
    const startDate = DateTime.fromISO (reservation.start_date).startOf ('day')
    const endDate = DateTime.fromISO (reservation.end_date).startOf ('day')
    const currentDate = DateTime.fromJSDate (selectedDateRef.current).startOf ('day')

    setSchedules (prevSchedules => [
      ...prevSchedules.filter (r => r.id !== reservation.id),
      ...(startDate <= currentDate && endDate >= currentDate ? [reservation] : [])
    ].sort (compareSchedules))
  }

  const handleEditSchedule = schedule => {
    const scheduleDate = DateTime.fromISO (schedule.date).startOf ('day')
    const currentDate = DateTime.fromJSDate (selectedDateRef.current).startOf ('day')

    setSchedules (prevSchedules => [
      ...prevSchedules.filter (s => s.id !== schedule.id),
      ...(scheduleDate.equals (currentDate) ? [schedule] : [])
    ].sort (compareSchedules))
  }

  const handleEditScheduleStatus = editedSchedules => {
    if (editedSchedules[0]?.date === DateTime.fromJSDate (selectedDateRef.current).toISODate ())
      setSchedules (prevSchedules => [
        ...prevSchedules.filter (schedule => !editedSchedules.some (s => s.id === schedule.id)),
        ...editedSchedules
      ].sort (compareSchedules))
  }

  const handleDeleteSchedule = schedule => {
    setSchedules (prevSchedules => prevSchedules.filter (s => s.id !== schedule.id))
  }
  
  const handleDeleteAllSchedules = deletedSchedules => {
    setSchedules (prevSchedules => (
      prevSchedules.filter (schedule => !deletedSchedules.some (s => s.id === schedule.id))
    ))
  }

  const handleRemoveProfessional = professional => {
    setProfessionals (prevProfessionals => prevProfessionals.filter (p => p.id !== professional.id))
    setSchedules (prevSchedules => prevSchedules.filter (s => s.professional.id !== professional.id))
  }

  const toggleCreateReservationModal = () => setShowCreateReservationModal (!showCreateReservationModal)
  const toggleScheduleLinkModal = () => setShowScheduleLinkModal (!showScheduleLinkModal)
  const togglePictureDialog = () => setShowPictureDialog (!showPictureDialog)
  const toggleInactiveDialog = () => setShowInactiveDialog (!showInactiveDialog)
  const handleTutorialCompleted = () => setShowTutorial (false)

  return (
    <Container>
      <Navbar/>
      {validatingConfig ? (
        <Loading style={{marginTop: 10, marginLeft: 20}}/>
      ) : showTutorial ? (
        <Tutorial
          businessConfig={businessConfig}
          onCompleted={handleTutorialCompleted}
        />
      ) : (
        <SubContainer>
          <Header/>
          <CalendarContainer>
            <LogoContainer>
              {isBusiness ? (
                <ImageButton onClick={togglePictureDialog}>
                  <Image
                    src={isBusiness ? user.logo : user.profile_picture}
                    alt={user.name}
                    data-for='changePicture'
                    data-tip
                  />
                  {business?.ratings_count > 0 && (
                    <RatingContainer>
                      <FaStar size={12} color='#FFC100'/>
                      <SubTitle>
                        {business?.average_rating}
                      </SubTitle>
                    </RatingContainer>
                  )}
                  <ReactTooltip
                    id='changePicture'
                    effect='solid'
                    place='right'
                    backgroundColor='#252525'
                    tooltipRadius='10'
                  >
                    <TooltipLabel>Alterar foto</TooltipLabel>
                  </ReactTooltip>
                </ImageButton>
              ) : (
                <Image src={isBusiness ? user.logo : user.profile_picture} alt={user.name}/>
              )}
              <SubTitle>{user.name}</SubTitle>
            </LogoContainer>
            <Row>
              <ClearableInput
                placeholder='Pesquisar por nome'
                value={query}
                onChange={e => setQuery (e.target.value)}
                onClear={() => setQuery ('')}
                containerStyle={{width: '100%'}}
                inputStyle={{boxShadow: '0px 1px 10px -6px', paddingInline: 12}}
                data-for='search'
                data-tip
              />
              <ReactTooltip
                id='search'
                effect='solid'
                place='right'
                backgroundColor='#252525'
                tooltipRadius='10'
              >
                <TooltipLabel>Pesquisar agendamentos por nome do cliente</TooltipLabel>
              </ReactTooltip>
            </Row>
            <Row>
              <Calendar
                onChange={date => {
                  setPrevDate (selectedDate)
                  setSelectedDate (date)
                }}
                value={selectedDate}
                calendarType = 'US'
              />
            </Row>
            {isBusiness && (
              <LinkButton to='/business/add-professional'>
                <FaUserPlus size={16} color='#FFC100'/>
                Cadastrar profissional
              </LinkButton>
            )}
            <Button onClick={toggleCreateReservationModal}>
              <IoCalendarOutline size={16} color='#FFC100'/>
              Reservar Agenda
            </Button>
            <Button onClick={toggleScheduleLinkModal}>
              <FaLink size={14} color='#FFC100'/>
              Agendar por link
            </Button>
          </CalendarContainer>
          {loading ? (
            <SubContainer style={{marginLeft: 10, zIndex: 6}}>
              <Loading/>
            </SubContainer>
          ) : (
            <Schedules>
              {professionals.length > 0 ? (
                <SchedulesTable
                  professionals={professionals}
                  schedules={filterSchedulesByCustomer (schedules)}
                  timeWindows={timeWindows}
                  timeInterval={timeInterval}
                  date={selectedDate}
                  numRows={timeWindows.length}
                  numColumns={professionals.length}
                  labels={timeWindows}
                  query={query}
                  onScheduleCreate={handleCreateSchedule}
                  onEditSchedule={handleEditSchedule}
                  onEditReservation={handleEditReservation}
                  onDeleteSchedule={handleDeleteSchedule}
                  onDeleteAllSchedules={handleDeleteAllSchedules}
                  onEditScheduleStatus={handleEditScheduleStatus}
                  onRemoveProfessional={handleRemoveProfessional}
                />
              ) : (
                <Label style={{marginLeft: 10}}>
                  Clique em "Cadastrar profissional" para começar a cadastrar seus profissionais. 
                  Os profissionais aparecerão aqui
                </Label>
              )}
            </Schedules>
          )}
          <CreateReservationModal
            visible={showCreateReservationModal}
            onClose={toggleCreateReservationModal}
            onConfirm={handleCreateReservations}
          />
          <ScheduleLinkModal
            visible={showScheduleLinkModal}
            onClose={toggleScheduleLinkModal}
          />
          <ChangePictureModal
            title='Alterar foto de perfil'
            visible={showPictureDialog}
            onConfirm={handleChangePicture}
            onClose={togglePictureDialog}
          />
          <InactiveBusinessModal
            visible={showInactiveDialog && !isAdmin}
            onClose={toggleInactiveDialog}
          />
        </SubContainer>
      )}
    </Container>
  )
}

export default withRouter (Agenda)
