import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Homepage from './pages/Homepage'
import PrivateRoute from './components/PrivateRoute'
import AdminRoute from './components/AdminRoute'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import Agenda from './pages/Agenda'
import Customers from './pages/Customers'
import Schedules from './pages/Schedules'
import Dashboard from './pages/Dashboard'
import Config from './pages/Config'
import EditProfessional from './pages/EditProfessional'
import AddProfessional from './pages/AddProfessional'
import BusinessRatings from './pages/Ratings/Business'
import ProfessionalRatings from './pages/Ratings/Professional'
import Financial from './pages/Financial'
import TotalEarnings from './pages/Financial/Comissions/Total'
import DailyEarnings from './pages/Financial/Comissions/Daily'
import ForgotPassword from './pages/ForgotPassword'
import ScheduleLink from './pages/ScheduleLink'
import Confirmed from './pages/ScheduleLink/Confirmed'
import CustomerSchedules from './pages/CustomerSchedules'

import AdminSignIn from './pages/Admin/SignIn'
import AdminFinancial from './pages/Admin/Financial'
import AdminDailyEarnings from './pages/Admin/Financial/Earnings/Daily'
import AdminDailyExpenses from './pages/Admin/Financial/Expenses/Daily'
import AdminMonthlyExpenses from './pages/Admin/Financial/Expenses/Monthly'
import Businesses from './pages/Admin/Businesses'
import Business from './pages/Admin/Business'
import Members from './pages/Admin/Members'
import Member from './pages/Admin/Member'
import Usage from './pages/Admin/Usage'
import AdminDailyUsage from './pages/Admin/Usage/Daily'
import AdminConfig from './pages/Admin/Config'
import NewService from './pages/Admin/Config/Services/NewService'
import EditService from './pages/Admin/Config/Services/EditService'
import NewProduct from './pages/Admin/Config/Products/NewProduct'
import EditProduct from './pages/Admin/Config/Products/EditProduct'
import NewServiceCategory from './pages/Admin/Config/ServiceCategories/NewServiceCategory'
import EditServiceCategory from './pages/Admin/Config/ServiceCategories/EditServiceCategory'
import NewProductCategory from './pages/Admin/Config/ProductCategories/NewProductCategory'
import EditProductCategory from './pages/Admin/Config/ProductCategories/EditProductCategory'

require ('dotenv').config ()

function App () {
  return (
    <Router>
      <Switch>
        <Route path='/' exact component={Homepage}/>
        <Route path='/login' component={SignIn}/>
        <Route path='/signup' component={SignUp}/>
        <Route path='/forgot-password' component={ForgotPassword}/>
        <PrivateRoute path='/business' exact component={Agenda}/>
        <PrivateRoute path='/business/customers' exact component={Customers}/>
        <PrivateRoute path='/business/schedules' exact component={Schedules}/>
        <PrivateRoute path='/business/dashboard' exact component={Dashboard}/>
        <PrivateRoute path='/business/config' component={Config}/>
        <PrivateRoute path='/business/add-professional' component={AddProfessional}/>
        <PrivateRoute path='/business/professionals/:id/edit' component={EditProfessional}/>
        <PrivateRoute path='/business/ratings' component={BusinessRatings}/>
        <PrivateRoute path='/business/professionals/:id/ratings' component={ProfessionalRatings}/>
        <PrivateRoute path='/business/financial' exact component={Financial}/>
        <PrivateRoute path='/business/financial/total' component={TotalEarnings}/>
        <PrivateRoute path='/business/financial/daily' component={DailyEarnings}/>
        <Route path='/businesses/:business_id/schedule' exact component={ScheduleLink}/>
        <Route path='/businesses/:business_id/schedule/:schedule_id' exact component={ScheduleLink}/>
        <Route path='/schedule/success' exact component={Confirmed}/>
        <Route path='/customers/:customer_id/schedules' exact component={CustomerSchedules}/>

        <Route path='/admin/login' component={AdminSignIn}/>
        <AdminRoute path='/admin' exact component={AdminFinancial}/>
        <AdminRoute path='/admin/earnings/daily' exact component={AdminDailyEarnings}/>
        <AdminRoute path='/admin/expenses/monthly' exact component={AdminMonthlyExpenses}/>
        <AdminRoute path='/admin/expenses/daily' exact component={AdminDailyExpenses}/>
        <AdminRoute path='/admin/businesses' exact component={Businesses}/>
        <AdminRoute path='/admin/businesses/:id' exact component={Business}/>
        <AdminRoute path='/admin/members' exact component={Members}/>
        <AdminRoute path='/admin/members/:id' exact component={Member}/>
        <AdminRoute path='/admin/usage' exact component={Usage}/>
        <AdminRoute path='/admin/usage/daily' exact component={AdminDailyUsage}/>
        <AdminRoute path='/admin/config' exact component={AdminConfig}/>
        <AdminRoute path='/admin/services/new' exact component={NewService}/>
        <AdminRoute path='/admin/services/:id/edit' exact component={EditService}/>
        <AdminRoute path='/admin/products/new' exact component={NewProduct}/>
        <AdminRoute path='/admin/products/:id/edit' exact component={EditProduct}/>
        <AdminRoute path='/admin/service_categories/new' exact component={NewServiceCategory}/>
        <AdminRoute path='/admin/service_categories/:id/edit' exact component={EditServiceCategory}/>
        <AdminRoute path='/admin/product_categories/new' exact component={NewProductCategory}/>
        <AdminRoute path='/admin/product_categories/:id/edit' exact component={EditProductCategory}/>
      </Switch>
    </Router>
  )
}

export default App
