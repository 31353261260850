import axios from 'axios'

const DEV = false
export const siteUrl = DEV ? process.env.REACT_APP_SITE_URL : 'https://cutilei.com'
export const apiUrl = DEV ? process.env.REACT_APP_API_URL : 'https://api.cutilei.com'

const cutileiApi = axios.create ({
  baseURL: apiUrl
})

export default cutileiApi
