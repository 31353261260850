import styled from 'styled-components'

export const modalStyle = {
  overlay: {
    zIndex: 5
  },
  content: {
    padding: 0,
    top: 150,
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    zIndex: 5,
    overflow: 'visible',
    marginRight: '-50%',
    transform: 'translate(-50%, 0%)',
    borderRadius: 15,
    boxShadow: '0px 2px 15px -8px #252525'
  }
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`

export const CalendarContainer = styled(Container)`
  .react-calendar {
    width: 300px;
    max-width: 100%;
    background: #FFFFFF;
    box-shadow: none !important;
    border-radius: 15px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    padding: 5px;
  }

  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    border-radius: 15px;
  }

  .react-calendar button:enabled:hover {
    cursor: pointer;
  }

  .react-calendar__navigation {
    height: 36px;
    margin-bottom: 0.5em !important;
    display: flex;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #F0F0F0;
  }

  .react-calendar__navigation button[disabled] {
    background-color: #F0F0F0;
  }

  button.react-calendar__navigation__label {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button.react-calendar__navigation__label span {
    font-size: 12px !important;
  }

  button.react-calendar__navigation__arrow {
    font-size: 20px !important;
  }

  button.react-calendar__decade-view__years__year {
    font-size: 14px !important;
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: capitalize !important;
    font-weight: bold;
    font-size: 0.7em !important;
    margin-bottom: 0.3em;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em 0em;
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    font-size: 12px !important;
    text-decoration: none !important;
  }

  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }

  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6em !important;
    padding: calc(0.6em / 0.6) calc(0.5em / 0.6);
  }

  .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr);
    gap: 2px;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #000000 !important;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #DDDDDD !important;
  }

  .react-calendar__tile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background: none;
    border-radius: 10px !important;
    font-size: 0.8em !important;
  }

  .react-calendar__tile:disabled {
    color: #CCCCCC !important;
    background-color: #FFFFFF !important;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #FFC100 !important;
    color: #FFFFFF !important;
  }

  .react-calendar__tile--now {
    background-color: #252525 !important;
    color: #FFFFFF !important;
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #252525 !important;
  }

  .react-calendar__tile--hasActive {
    color: #FFFFFF;
    background: #FFD862;
  }

  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #A9D4FF;
  }

  .react-calendar__tile--active {
    background-color: #F0F0F0 !important;
    color: #000000 !important;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background-color: #FFC100 !important;
    color: #FFFFFF !important;
  }

  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #F0F0F0 !important;
    color: #000000 !important;
  }
`

export const Button = styled.button`
  width: 100%;
  height: 38px;
  border: 0px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 15px;
  box-shadow: 0px 2px 10px -6px #252525;

  &:hover {
    background-color: #F7F7F7;
  }
`

export const InfoText = styled.p`
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin: 0px;
`
