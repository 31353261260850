import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import cutileiApi from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import ScheduleService from '../../../services/Schedule'
import NumberInput from '../../../components/Inputs/NumberInput'
import MoneyInput from '../../../components/Inputs/MoneyInput'
import Select from '../../../components/Inputs/Select'
import CheckBox from '../../../components/CheckBox'
import editServiceValidator from '../../../validators/editServiceValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import ReactTooltip from 'react-tooltip'
import { Formik } from 'formik'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Form,
  FormField,
  ProductGrid,
  Input,
  Button,
  SmallButton,
  CloseButton,
  DangerButton,
  Row,
  Title,
  SubTitle,
  InfoText,
  Label,
  WarningText,
  ErrorContainer
} from './styles'

function EditServiceModal ({
  visible,
  professionalID,
  data: service,
  businessProducts = [],
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  
  const calculateComissionValue = (price, comissionPercentage) => {
    return price * (comissionPercentage / 100 || 0)
  }

  const calculateComissionPercentage = (price, comissionValue) => {
    return Math.round ((comissionValue / price) * 100)
  }

  const recalcutaleComissionValue = (values, setFieldValue) => {
    const comissionValue = calculateComissionValue (values.price, values.comission_percentage)
    setFieldValue ('professional_comission', comissionValue)
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Formik
        validationSchema={editServiceValidator}
        initialValues={{
          'id': service.id,
          'nickname': service.nickname || service.name,
          'duration': service.duration,
          'max_delay': service.max_delay,
          'price': service.price,
          'comission_percentage': service.comission_percentage * 100,
          'professional_comission': service.comission_percentage * service.price ?? 0,
          'business_comission': service.price - (service.comission_percentage * service.price ?? 0),
          'cutilei_service': service.cutilei_service,
          'accepts_online': service.accepts_online,
          'products': service.products ?? []
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setErrorMessage (null)
          setSubmitting (true)

          try {
            const data = {...values, comission_percentage: values.comission_percentage / 100}
            const url = professionalID
              ? `/businesses/${businessId}/professionals/${professionalID}/services/${values.id}`
              : `/businesses/${businessId}/services/${values.id}`

            await cutileiApi.put (url, data, requestConfig)

            setSubmitting (false)
            handleConfirm ({...service, ...data})
            handleClose ()
          } catch (error) {
            setSubmitting (false)
            console.log (error)
          }
        }}
      >
        {function EditServiceForm ({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur, 
          handleSubmit
        }) {
          useEffect (() => {
            recalcutaleComissionValue (values, setFieldValue)
          }, [values.price])

          useEffect (() => {
            setFieldValue ('business_comission', Math.abs (values.price - values.professional_comission))
          }, [values.price, values.comission_percentage, values.professional_comission])

          const handleAddProduct = () => {
            setFieldValue ('products', [
              ...values.products, {
                id: '',
                name: '',
                quantity_used: 1
              }
            ])
          }

          const handleDeleteProduct = index => {
            setFieldValue (
              'products',
              values.products.map ((p, i) => ({...p, idx: i})).filter (s => s.idx !== index)
            )
          }

          return (
            <Form onSubmit={handleSubmit}>
              <Title>Editar serviço</Title>
              <CloseButton onClick={handleClose}>
                <FA.FaTimes color='#FF3939' size={18}/>
              </CloseButton>
              <Row style={{
                display: 'grid',
                gridTemplateColumns: values.cutilei_service ? '1fr 1fr' : '2fr 1fr 1fr'
              }}>
                {!values.cutilei_service && (
                  <FormField>
                    <Label htmlFor='nickname'>
                      Apelido
                    </Label>
                    <Input
                      id='nickname'
                      placeholder='Apelido'
                      value={values.nickname}
                      onChange={handleChange ('nickname')} 
                      onBlur={handleBlur ('nickname')}
                    />
                  </FormField>
                )}
                <FormField>
                  <Label htmlFor='duration'>
                    Tempo do serviço (mins) {values.duration >= 60 && ` - ${
                      ScheduleService.getFormattedDuration (values.duration)
                    }`}
                  </Label>
                  <NumberInput
                    id='duration'
                    name='duration'
                    value={values.duration}
                    minValue={15}
                    stepOnly={true}
                    step={values.duration < 60 ? 15 : 30}
                    onChange={(name, value) => {
                      const newValue = values.duration === 60 && values.duration > value ? 45 : value
                      setFieldValue (name, newValue)
                    }}
                    style={{width: '100%'}}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='max_delay'>
                    Tolerância de atraso (mins)
                  </Label>
                  <NumberInput
                    id='max_delay'
                    name='max_delay'
                    value={values.max_delay}
                    minValue={5}
                    maxValue={values.cutilei_service ? 20 : 60}
                    onChange={setFieldValue}
                    style={{width: '100%'}}
                    data-for='max_delay_tooltip'
                    data-tip
                  />
                  <ReactTooltip
                    id='max_delay_tooltip'
                    effect='solid'
                    place='bottom' 
                    backgroundColor='#252525'
                    tooltipRadius='10'
                  >
                    <Label style={{color: '#FFFFFF', width: 200}}>
                      A tolerância de atraso para serviços pode ser configurada entre 5 e 20 minutos.
                    </Label>
                  </ReactTooltip>
                </FormField>
              </Row>
              <Row style={{alignItems: 'flex-start'}}>
                <FormField>
                  <Label htmlFor='price'>
                    Preço (R$)
                  </Label>
                  <MoneyInput
                    id='price'
                    name='price'
                    placeholder='Preço'
                    value={values.price}
                    onValueChange={setFieldValue}
                    onBlur={handleBlur ('price')}
                    disabled={values.cutilei_service}
                  />
                  {touched.price && errors.price && (
                    <WarningText>
                      {errors.price}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Label htmlFor='comission_percentage'>
                    Comissão (%)
                  </Label>
                  <NumberInput
                    id='comission_percentage'
                    name='comission_percentage'
                    placeholder='Comissão (%)'
                    value={values.comission_percentage}
                    minValue={0}
                    maxValue={100}
                    onChange={(name, value) => {
                      setFieldValue (name, value)
                      setFieldValue ('professional_comission', calculateComissionValue (values.price, value))
                    }}
                    onBlur={handleBlur}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='professional_comission'>
                    Valor (profissional)
                  </Label>
                  <MoneyInput
                    id='professional_comission'
                    name='professional_comission'
                    placeholder='Valor líquido'
                    value={values.professional_comission}
                    maxValue={values.price}
                    onValueChange={(name, value) => {
                      setFieldValue (name, value)
                      setFieldValue ('comission_percentage', calculateComissionPercentage (values.price, value))
                    }}
                    onBlur={handleBlur ('professional_comission')}
                    style={{color: '#44CF6C', fontWeight: 600}}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='business_comission'>
                    Valor (salão)
                  </Label>
                  <MoneyInput
                    id='business_comission'
                    name='business_comission'
                    value={values.business_comission}
                    style={{color: '#44CF6C', fontWeight: 600}}
                    disabled
                  />
                </FormField>
              </Row>
              {!values.cutilei_service && (
                <FormField>
                  <Row style={{alignItems: 'center', marginBottom: 7}}>
                    <CheckBox
                      id='accepts_online'
                      style={{marginRight: -3}}
                      value={values.accepts_online}
                      onValueChange={value => setFieldValue ('accepts_online', value)}
                    />
                    <Label htmlFor='accepts_online' style={{margin: 0}}>
                      Permitir agendamento por link
                    </Label>
                  </Row>
                </FormField>
              )}
              {(!professionalID && values.products.length > 0) && values.products.map ((product, index) => (
                <ProductGrid key={index}>
                  <FormField>
                    <Label>Produto</Label>
                    <Select
                      name={`products.${index}.id`}
                      placeholder='Selecionar...'
                      value={product.id ? {
                        label: product.nickname || product.name,
                        value: product.id
                      } : null}
                      options={businessProducts}
                      onChange={(name, option) => {
                        setFieldValue (name, option.value.id)
                        setFieldValue (`products.${index}.name`, option.value.nickname || option.value.name)
                      }}
                      onBlur={setFieldTouched}
                      error={errors.products?.[index]?.name}
                      touched={touched.products?.[index]?.name}
                    />
                    {touched.products?.[index]?.name && errors.products?.[index]?.name && (
                      <WarningText>
                        {errors.products?.[index]?.name}
                      </WarningText>
                    )}
                  </FormField>
                  <Row style={{margin: 0}}>
                    <FormField>
                      <Label>Qtd. utilizada</Label>
                      <NumberInput
                        name={`products.${index}.quantity_used`}
                        value={product.quantity_used}
                        minValue={1}
                        maxValue={999999}
                        onChange={setFieldValue}
                      />
                    </FormField>
                    {product.id && (
                      <InfoText style={{marginBottom: 8}}>
                        {businessProducts.find (p => p.value.id === product.id)?.value.content_unit}
                      </InfoText>
                    )}
                  </Row>
                  <DangerButton onClick={() => handleDeleteProduct (index)}>
                    <FA.FaTrash color='#FFFFFF' size={12}/>
                  </DangerButton>
                </ProductGrid>
              ))}
              {!professionalID && (
                <SmallButton onClick={handleAddProduct}>
                  <FA.FaPlus size={12}/>
                  <SubTitle>Adicionar produto</SubTitle>
                </SmallButton>
              )}
              {errorMessage && (
                <ErrorContainer>
                  <p>{errorMessage}</p>
                </ErrorContainer>
              )}
              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? <ButtonLoading/> : 'Salvar alterações'}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default EditServiceModal
