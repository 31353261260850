import React from 'react'
import Product from '../../../services/Product'
import NumberInput from '../../Inputs/NumberInput'
import MoneyInput from '../../Inputs/MoneyInput'
import Select from '../../Inputs/Select'
import CheckBox from '../../CheckBox'

import {
  Container,
  DataGrid,
  FormGrid,
  FormField,
  Row,
  Input,
  Label,
  SubTitle,
  InfoText,
  WarningText
} from './styles'

function ProductForm ({
  data: product,
  index,
  formikProps
}) {
  const {
    values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur
  } = formikProps
  
  const selected = values.products[index]?.selected

  return (
    <Container selected={selected}>
      <DataGrid onClick={() => setFieldValue (`products.${index}.selected`, !selected)}>
        <Row>
          <CheckBox
            id={`products[${index}].selected`}
            value={values.products[index]?.selected}
            onValueChange={value => setFieldValue (`products.${index}.selected`, value)}
          />
          <SubTitle>
            {product.name}
          </SubTitle>
        </Row>
        <InfoText>
          {product.category.name}
        </InfoText>
      </DataGrid>
      {selected && (
        <FormGrid>
          <FormField>
            <Label>Apelido</Label>
            <Input
              placeholder='Apelido (opcional)'
              value={values.products[index]?.nickname}
              onChange={handleChange (`products.${index}.nickname`)} 
              onBlur={handleBlur (`products.${index}.nickname`)}
              style={{marginTop: 0}}
            />
          </FormField>
          <FormField>
            <Label>Qtd. estoque</Label>
            <NumberInput
              name={`products.${index}.total_stock`}
              value={values.products[index]?.total_stock}
              minValue={1}
              maxValue={999999}
              onChange={setFieldValue}
            />
          </FormField>
          <FormField>
            <Label>Custo por unidade</Label>
            <MoneyInput
              name={`products.${index}.cost`}
              placeholder='Custo por unidade'
              value={values.products[index]?.cost}
              onValueChange={setFieldValue}
              onBlur={handleBlur (`products.${index}.cost`)}
            />
            {touched.products?.[index]?.cost && errors.products?.[index]?.cost && (
              <WarningText>
                {errors.products[index]?.cost}
              </WarningText>
            )}
          </FormField>
          <FormField>
            <Label>Qtd. por embalagem</Label>
            <NumberInput
              name={`products.${index}.total_content`}
              placeholder='Conteúdo total'
              value={values.products[index]?.total_content}
              minValue={1}
              maxValue={999999}
              onChange={setFieldValue}
              onBlur={handleBlur}
            />
          </FormField>
          <FormField>
            <Label>Unidade de medida</Label>
            <Select
              name={`products.${index}.content_unit`}
              placeholder='Selecionar...'
              value={values.products[index]?.content_unit}
              options={Product.UNIT_OPTIONS}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.products?.[index]?.content_unit}
              touched={touched.products?.[index]?.content_unit}
            />
          </FormField>
          <FormField>
            <Label>Finalidade</Label>
            <Select
              name={`products.${index}.use_in_service`}
              placeholder='Selecionar...'
              menuPlacement='top'
              value={values.products[index]?.use_in_service}
              options={Product.USE_OPTIONS}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.products?.[index]?.use_in_service}
              touched={touched.products?.[index]?.use_in_service}
            />
            {touched.products?.[index]?.use_in_service && errors.products?.[index]?.use_in_service && (
              <WarningText>
                {errors.products[index]?.use_in_service}
              </WarningText>
            )}
          </FormField>
          {values.products[index]?.use_in_service?.value === false && (
            <>
              <FormField>
                <Label>Preço (R$)</Label>
                <MoneyInput
                  name={`products.${index}.price`}
                  placeholder='Preço'
                  value={values.products[index]?.price}
                  onValueChange={setFieldValue}
                  onBlur={handleBlur (`products.${index}.price`)}
                />
                {touched.products?.[index]?.price && errors.products?.[index]?.price && (
                  <WarningText>
                    {errors.products[index]?.price}
                  </WarningText>
                )}
              </FormField>
              <FormField>
                <Label>Comissão (%)</Label>
                <NumberInput
                  name={`products.${index}.comission_percentage`}
                  value={values.products[index]?.comission_percentage}
                  minValue={0}
                  maxValue={100}
                  onChange={setFieldValue}
                />
              </FormField>
            </>
          )}
        </FormGrid>
      )}
    </Container>
  )
}

export default ProductForm
