import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 15px;
  background-color: #FFFFFF;
  padding: 8px 15px 10px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 5px;
`

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 14px;
  text-align: left;
  line-height: 14px;
  margin: 0px;
`

export const Title = styled(InfoText)`
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
`

export const SubTitle = styled(Title)`
  color: #FFC100;
  font-size: 14px;
`

export const StatusText = styled(SubTitle)`
  color: ${({ validated }) => validated ? '#44CF6C' : '#FF3939'};
  position: absolute;
  bottom: 10px;
  right: 15px;
`

export const Button = styled.button.attrs ({
  type: 'button'
})`
  background-color: #252525;
  color: #F7F7F7;
  width: 100px;
  height: 38px;
  border-radius: 15px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 10px;
  font-size: 14px;
  border: none;

  &:hover {
    color: #FFFFFF;
  }
`

export const DangerButton = styled(Button)`
  background-color: #FF3939;

  &:hover {
    background-color: #FF2020;
    color: #FFFFFF;
  }
`
