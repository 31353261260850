import React, { useState, useEffect } from 'react'
import cutileiApi from '../../../services/cutileiApi'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
// import * as FA from 'react-icons/fa'

import {
  SubContainer,
  // RatingContainer,
  ProfessionalPicture,
  Column,
  Row,
  ProfessionalsGrid,
  SmallButton,
  SubTitle
} from '../styles'

function ProfessionalsForm ({
  schedule = {},
  onProfessionalSelected: handleProfessionalSelected = () => null
}) {
  const [loading, setLoading] = useState (true)
  const [professionals, setProfessionals] = useState ([])
  const { business, service, date, time } = schedule
  
  useEffect (() => {
    getProfessionals ()
  }, [])

  const getProfessionals = async () => {
    const filters = `access_level=service&status=active&schedule_id=${schedule.id}`
                  + `&service_id=${service.id}&accepts_online=true`
                  + `&schedule_date=${date}&schedule_time=${time}&schedule_duration=${service.duration}`

    try {
      const { data: professionals } = await cutileiApi.get (
        `/businesses/${business.id}/professionals?${filters}`
      )
      setProfessionals (professionals)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <SubContainer>
      <SubTitle>Escolha o(a) profissional</SubTitle>
      {loading ? (
        <Loading style={{marginTop: 20}}/>
      ) : (
        <ProfessionalsGrid>
          {professionals.map ((professional, index) => (
            <SmallButton
              key={index}
              onClick={() => handleProfessionalSelected (professional)}
              style={{margin: 0}}
            >
              <Column>
                <Row style={{position: 'relative', marginBottom: 4}}>
                  <ProfessionalPicture src={professional.profile_picture}/>
                  {/* {professional.average_rating > 0 && (
                    <RatingContainer>
                      <FA.FaStar size={14} color='#FFC100'/>
                      <SubTitle>
                        {professional.average_rating}
                      </SubTitle>
                    </RatingContainer>
                  )} */}
                </Row>
                <SubTitle>
                  {professional.nickname || professional.name.split (' ')[0]}
                </SubTitle>
              </Column>
            </SmallButton>
          ))}
        </ProfessionalsGrid>
      )}
    </SubContainer>
  )
}

export default ProfessionalsForm
