import React, { useState } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import AlertDialog from '../../../AlertDialog'
import LoadingBar from '../../../LoadingBar'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'

import {
  Container,
  Row,
  Column,
  SubTitle,
  InfoText,
  MoneyTextMask,
  Button
} from './styles'

function BusinessProduct ({
  data: product,
  onEdit,
  onDelete: handleDelete
}) {
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [deleting, setDeleting] = useState (false)
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const handleEdit = () => onEdit (product)

  const deleteProduct = async () => {
    setDeleting (true)
    try {
      await cutileiApi.delete (`/businesses/${businessId}/products/${product.id}`, requestConfig)
      setDeleting (false)
      handleDelete (product)
    } catch (error) {
      setDeleting (false)
      console.log (error)
    }
  }

  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)

  return (
    <Container>
      <Row>
        <SubTitle>
          {product.nickname || product.name}
        </SubTitle>
      </Row>
      <Column>
        <LoadingBar
          currentValue={product.available_stock}
          totalValue={product.total_stock}
        />
        {product.use_in_service && (
          <LoadingBar
            currentValue={product.available_content}
            totalValue={product.total_content}
            valueUnit={product.content_unit}
          />
        )}
      </Column>
      <InfoText>
        {product.use_in_service ? 'Uso em serviços' : 'Venda'}
      </InfoText>
      {product.use_in_service ? (
        <InfoText>-</InfoText>
      ) : (
        <MoneyTextMask value={product.price ?? 0} style={{justifySelf: 'center'}}/>
      )}
      <InfoText>
        {product.use_in_service ? '-' : `${product.comission_percentage * 100}%`}
      </InfoText>
      <Row style={{justifySelf: 'center'}}>
        <Button onClick={handleEdit}>
          <FA.FaEdit color='#35A7FF' size={18}/>
        </Button>
        <Button onClick={toggleAlertDialog}>
          {deleting ? (
            <ButtonLoading style={{margin: 0}}/>
          ) : (
            <FA.FaTrash color='#FF3939' size={16}/>
          )}
        </Button>
      </Row>
      <AlertDialog
        visible={showAlertDialog}
        title='Atenção!'
        message='Deseja realmente excluir este produto?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deleteProduct}
        onClose={toggleAlertDialog}
        dangerous
      />
    </Container>
  )
}

export default BusinessProduct
