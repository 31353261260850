import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../services/cutileiApi'
import CustomerSchedule from '../../components/CustomerSchedule'
import { ReactComponent as Loading } from '../../icons/loading2.svg'
import CutileiLogo from '../../icons/logo-cutilei-simple.png'

import {
  Container,
  SubContainer,
  SchedulesGrid,
  LogoRow,
  SmallImage,
  Title,
  InfoText
} from './styles'

function CustomerSchedules ({ history, match }) {
  const { customer_id } = match.params
  const [loading, setLoading] = useState (true)
  const [customer, setCustomer] = useState ({})
  const [schedules, setSchedules] = useState ([])

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      const { data: customer } = await cutileiApi.get (`/customers/${customer_id}`)
      const { data: schedules } = await cutileiApi.get (
        `/customers/${customer_id}/schedules?status=next`
      )

      setCustomer (customer)
      setSchedules (schedules.data)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const handleEditSchedule = schedule => {
    const { business } = schedule
    history.push (`/businesses/${business.id}/schedule/${schedule.id}`)
  }

  const handleDeleteSchedule = schedule => {
    setSchedules (schedules.filter (s => s.id !== schedule.id))
  }

  return (
    <Container>
      <LogoRow>
        <SmallImage src={CutileiLogo} alt='Cutilei'/>
        <Title style={{margin: 0}}>Cutilei</Title>
      </LogoRow>
      {loading ? (
        <Loading style={{marginTop: 20}}/>
      ) : (
        <SubContainer>
          <Title>Olá, {customer.name.split (' ')[0]}!</Title>
          <InfoText>
            Você pode acompanhar, alterar ou cancelar seus agendamentos por aqui
          </InfoText>
          {schedules.length > 0 ? (
            <SchedulesGrid total={schedules.length}>
              {schedules.map ((schedule, index) => (
                <CustomerSchedule
                  key={index}
                  data={schedule}
                  showActions={true}
                  onEdit={handleEditSchedule}
                  onDelete={handleDeleteSchedule}
                />
              ))}
            </SchedulesGrid>
          ) : (
            <InfoText style={{marginTop: 20}}>
              Você ainda não possui agendamentos futuros
            </InfoText>
          )}
        </SubContainer>
      )}
    </Container>
  )
}

export default withRouter (CustomerSchedules)
