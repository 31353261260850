import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import Product from '../../../services/Product'
import NumberInput from '../../../components/Inputs/NumberInput'
import MoneyInput from '../../../components/Inputs/MoneyInput'
import Select from '../../../components/Inputs/Select'
import editProductValidator from '../../../validators/editProductValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import { Formik } from 'formik'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Form,
  FormField,
  Input,
  Button,
  CloseButton,
  Row,
  Title,
  Label,
  WarningText,
  ErrorContainer
} from './styles'

function EditProductModal ({
  visible,
  data: product,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const calculateComissionValue = (price, comissionPercentage) => {
    return price * (comissionPercentage / 100 || 0)
  }

  const calculateComissionPercentage = (price, comissionValue) => {
    return Math.round ((comissionValue / price) * 100)
  }

  const recalcutaleComissionValue = (values, setFieldValue) => {
    const comissionValue = calculateComissionValue (values.price, values.comission_percentage)
    setFieldValue ('professional_comission', comissionValue)
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Formik
        validationSchema={editProductValidator}
        initialValues={{
          'id': product.id,
          'nickname': product.nickname,
          'use_in_service': Product.USE_OPTIONS.find (o => o.value === product.use_in_service),
          'total_stock': product.total_stock,
          'total_content': product.total_content,
          'content_unit': Product.UNIT_OPTIONS.find (o => o.value === product.content_unit),
          'cost': product.cost,
          'price': product.price || 0,
          'comission_percentage': (product.comission_percentage || 0) * 100,
          'professional_comission': product.comission_percentage * product.price ?? 0,
          'business_comission': product.price - (product.comission_percentage * product.price ?? 0)
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setErrorMessage (null)
          setSubmitting (true)

          try {
            const data = {
              ...values,
              available_stock: values.total_stock,
              available_content: values.total_content,
              use_in_service: values.use_in_service.value,
              comission_percentage: values.comission_percentage / 100,
              content_unit: values.content_unit.value
            }
              
            const url = `/businesses/${businessId}/products/${values.id}`

            await cutileiApi.put (url, data, requestConfig)

            setSubmitting (false)
            handleConfirm ({...product, ...data})
            handleClose ()
          } catch (error) {
            setSubmitting (false)
            console.log (error)
          }
        }}
      >
        {function EditProductForm ({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur, 
          handleSubmit
        }) {
          useEffect (() => {
            recalcutaleComissionValue (values, setFieldValue)
          }, [values.price])

          useEffect (() => {
            setFieldValue ('business_comission', Math.abs (values.price - values.professional_comission))
          }, [values.price, values.comission_percentage, values.professional_comission])

          return (
            <Form onSubmit={handleSubmit}>
              <Title>Editar produto</Title>
              <CloseButton onClick={handleClose}>
                <FA.FaTimes color='#FF3939' size={18}/>
              </CloseButton>
              <Row>
                <FormField stretch={2}>
                  <Label htmlFor='nickname'>
                    Apelido
                  </Label>
                  <Input
                    id='nickname'
                    placeholder='Apelido'
                    value={values.nickname}
                    onChange={handleChange ('nickname')} 
                    onBlur={handleBlur ('nickname')}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='total_stock'>
                    Qtd. estoque
                  </Label>
                  <NumberInput
                    id='total_stock'
                    name='total_stock'
                    value={values.total_stock}
                    minValue={1}
                    onChange={setFieldValue}
                    style={{width: '100%'}}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='cost'>
                    Custo por unidade
                  </Label>
                  <MoneyInput
                    id='cost'
                    name='cost'
                    placeholder='Custo por unidade'
                    value={values.cost}
                    onValueChange={setFieldValue}
                    onBlur={handleBlur ('cost')}
                  />
                  {touched.cost && errors.cost && (
                    <WarningText>
                      {errors.cost}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              <Row style={{alignItems: 'flex-start'}}>
                <FormField>
                  <Label htmlFor='total_content'>
                    Qtd. por embalagem
                  </Label>
                  <NumberInput
                    id='total_content'
                    name='total_content'
                    placeholder='Conteúdo total'
                    value={values.total_content}
                    minValue={1}
                    maxValue={999999}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    style={{width: '100%'}}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='content_unit'>
                    Unidade de medida
                  </Label>
                  <Select
                    id='content_unit'
                    name='content_unit'
                    placeholder='Selecionar...'
                    value={values.content_unit}
                    options={Product.UNIT_OPTIONS}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.content_unit}
                    touched={touched.content_unit}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='use_in_service'>
                    Finalidade
                  </Label>
                  <Select
                    id='use_in_service'
                    name='use_in_service'
                    placeholder='Selecionar...'
                    value={values.use_in_service}
                    options={Product.USE_OPTIONS}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.use_in_service}
                    touched={touched.use_in_service}
                  />
                  {touched.use_in_service && errors.use_in_service && (
                    <WarningText>
                      {errors.use_in_service}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              {values.use_in_service?.value === false && (
                <Row style={{alignItems: 'flex-start'}}>
                  <FormField>
                    <Label htmlFor='price'>
                      Preço (R$)
                    </Label>
                    <MoneyInput
                      id='price'
                      name='price'
                      placeholder='Preço'
                      value={values.price}
                      onValueChange={setFieldValue}
                      onBlur={handleBlur ('price')}
                    />
                    {touched.price && errors.price && (
                      <WarningText>
                        {errors.price}
                      </WarningText>
                    )}
                  </FormField>
                  <FormField>
                    <Label htmlFor='comission_percentage'>
                      Comissão (%)
                    </Label>
                    <NumberInput
                      id='comission_percentage'
                      name='comission_percentage'
                      placeholder='Comissão (%)'
                      value={values.comission_percentage}
                      minValue={0}
                      maxValue={100}
                      onChange={(name, value) => {
                        setFieldValue (name, value)
                        setFieldValue ('professional_comission', calculateComissionValue (values.price, value))
                      }}
                      onBlur={handleBlur}
                    />
                  </FormField>
                  <FormField>
                    <Label htmlFor='professional_comission'>
                      Valor (profissional)
                    </Label>
                    <MoneyInput
                      id='professional_comission'
                      name='professional_comission'
                      placeholder='Valor líquido'
                      value={values.professional_comission}
                      maxValue={values.price}
                      onValueChange={(name, value) => {
                        setFieldValue (name, value)
                        setFieldValue ('comission_percentage', calculateComissionPercentage (values.price, value))
                      }}
                      onBlur={handleBlur ('professional_comission')}
                      style={{color: '#44CF6C', fontWeight: 600}}
                    />
                  </FormField>
                  <FormField>
                    <Label htmlFor='business_comission'>
                      Valor (salão)
                    </Label>
                    <MoneyInput
                      id='business_comission'
                      name='business_comission'
                      value={values.business_comission}
                      style={{color: '#44CF6C', fontWeight: 600}}
                      disabled
                    />
                  </FormField>
                </Row>
              )}
              {errorMessage && (
                <ErrorContainer>
                  <p>{errorMessage}</p>
                </ErrorContainer>
              )}
              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? <ButtonLoading/> : 'Salvar alterações'}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default EditProductModal
