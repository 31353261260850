import React, { useState } from 'react'
import cutileiApi from '../../../services/cutileiApi'
import MaskedInputService from '../../../services/maskedInput'
import CustomerSchedule from '../../../components/CustomerSchedule'
import { Formik } from 'formik'
import scheduleCustomerValidator from '../../../validators/scheduleCustomerValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import { DateTime } from 'luxon'

import {
  SubContainer,
  FormField,
  Row,
  Form,
  Input,
  MaskedInput,
  Title,
  InfoText,
  Button,
  WarningText,
  ErrorContainer
} from '../styles'

function Confirmation ({
  schedule = {},
  onConfirm: handleConfirm = () => null
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const { business, customer } = schedule
  const isEdit = !!schedule.id

  return (
    <SubContainer>
      <Formik
        validationSchema={scheduleCustomerValidator}
        initialValues={{
          'firstName': customer?.name.split (' ')[0] ?? '',
          'lastName': customer?.name.split (' ').slice (1).join (' ') ?? '',
          'email': customer?.email ?? '',
          'phone': customer?.phone ?? '',
          'birth': customer ? DateTime.fromISO (customer.birth).toFormat ('dd/MM/yyyy') : ''
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setErrorMessage (null)
          try {
            const { firstName, lastName, birth, email, phone } = values

            const { data: customer } = await cutileiApi.post (
              `/businesses/${business.id}/customers`, {
                name: `${firstName.trim ()} ${lastName.trim ()}`,
                birth: DateTime.fromFormat (birth, 'ddMMyyyy').toISODate (),
                email: email.trim (),
                phone
              }
            )

            const scheduleValues = {
              businessId: business.id,
              date: schedule.date,
              time: schedule.time,
              serviceId: schedule.service.id,
              professionalId: schedule.professional.id,
              email: email.trim ()
            }

            if (isEdit) {
              const { data: editedSchedule } = await cutileiApi.put (
                `/customers/${customer.id}/schedules/${schedule.id}`, scheduleValues
              )
              handleConfirm (editedSchedule)
            } else {
              const { data: createdSchedule } = await cutileiApi.post (
                `/customers/${customer.id}/schedules`, scheduleValues
              )
              handleConfirm (createdSchedule)
            }
            
            setSubmitting (false)
          } catch (error) {
            setSubmitting (false)
            if (error.response.data) setErrorMessage (error.response.data.message)
            console.log (error)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form>
            <Title>Confirmação</Title>
            <FormField>
              <CustomerSchedule data={schedule} containerStyle={{width: '100%', marginBlock: 5}}/>
            </FormField>
            <InfoText>
              Preencha suas informações abaixo
            </InfoText>
            <FormField>
              <Input 
                placeholder='Nome'
                value={values.firstName}
                onChange={handleChange ('firstName')} 
                onBlur={handleBlur ('firstName')}
              />
              {touched.firstName && errors.firstName && (
                <WarningText>
                  {errors.firstName}
                </WarningText>
              )}
            </FormField>
            <FormField>
              <Input 
                placeholder='Sobrenome'
                value={values.lastName}
                onChange={handleChange ('lastName')} 
                onBlur={handleBlur ('lastName')}
              />
              {touched.lastName && errors.lastName && (
                <WarningText>
                  {errors.lastName}
                </WarningText>
              )}
            </FormField>
            <Row>
              <FormField>
                <Input
                  type='email'
                  placeholder='E-mail'
                  value={values.email}
                  onChange={handleChange ('email')}
                  onBlur={handleBlur ('email')}
                />
                {touched.email && errors.email && (
                  <WarningText>
                    {errors.email}
                  </WarningText>
                )}
              </FormField>
            </Row>
            <Row style={{alignItems: 'flex-start',gap: 10}}>
              <FormField>
                <MaskedInput
                  name='phone'
                  placeholder='Celular'
                  options={{
                    blocks: [0, 2, 5, 4],
                    delimiters: ['(', ') ', '-'],
                    numericOnly: true,
                  }}
                  value={values.phone}
                  onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                  onBlur={handleBlur ('phone')}
                />
                {touched.phone && errors.phone && (
                  <WarningText>
                    {errors.phone}
                  </WarningText>
                )}
              </FormField>
              <FormField>
                <MaskedInput
                  name='birth'
                  placeholder='Nascimento'
                  options={{date: true, datePattern: ['d', 'm', 'Y']}}
                  value={values.birth}
                  onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                  onBlur={handleBlur ('birth')}
                />
                {touched.birth && errors.birth && (
                  <WarningText>{errors.birth}</WarningText>
                )}
              </FormField>
            </Row>
            {errorMessage && (
              <ErrorContainer>
                <InfoText style={{color: '#FFFFFF'}}>
                  {errorMessage}
                </InfoText>
              </ErrorContainer>
            )}
            <Button onClick={handleSubmit} disabled={isSubmitting} style={{marginTop: 10, minWidth: 120}}>
              {isSubmitting ? (
                <ButtonLoading/>
              ) : (
                isEdit ? 'Confirmar alterações' : 'Agendar'
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </SubContainer>
  )
}

export default Confirmation
