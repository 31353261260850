import * as Yup from 'yup'

const professionalWorkingDaysValidator = Yup.object ({
  id: Yup.string (),

  working_days: Yup.array ().of (Yup.object ().shape ({
    selected: Yup.boolean (),
    accepts_online: Yup.boolean (),
    accepts_cutilei: Yup.boolean (),

    work_start: Yup.object ().nullable ().when ('selected', {
      is: true,
      then: schema => schema.required ('Selecione o horário de início de expediente')
    }),

    work_end: Yup.object ().nullable ().when ('selected', {
      is: true,
      then: schema => schema.required ('Selecione o horário de final de expediente')
    }),

    online_start: Yup.object ().nullable ().when ('accepts_online', {
      is: true,
      then: schema => schema.required ('Selecione o horário de início para agendamentos online')
    }),

    online_end: Yup.object ().nullable ().when ('accepts_online', {
      is: true,
      then: schema => schema.required ('Selecione o horário de término para agendamentos online')
    }),

    cutilei_start: Yup.object ().nullable ().when ('accepts_cutilei', {
      is: true,
      then: schema => schema.required ('Selecione o horário de início do atendimento Cutilei')
    }),

    cutilei_end: Yup.object ().nullable ().when ('accepts_cutilei', {
      is: true,
      then: schema => schema.required ('Selecione o horário de término do atendimento Cutilei')
    })
  }))
})

export default professionalWorkingDaysValidator
