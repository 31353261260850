import * as Yup from 'yup'

const createProductValidator = Yup.object ({
  name: Yup.string ()
    .required ('Digite um nome')
    .min (4, 'O nome deve conter no minimo 4 caracteres!')
    .max (64, 'O nome deve conter até 64 caracteres!'),

  brand: Yup.string ()
    .required ('Digite o nome da marca')
    .min (4, 'O nome da marca deve conter no minimo 4 caracteres!')
    .max (64, 'O nome da marca deve conter até 64 caracteres!'),

  description: Yup
    .string ()
    .max (256, 'A descrição deve ter até 256 caracteres'),

  category: Yup
    .object ()
    .nullable ()
    .required ('Selecione a categoria'),

  use_in_service: Yup
    .object ()
    .nullable ()
    .required ('Selecione a finalidade'),

  content_unit: Yup
    .object ()
    .nullable ()
    .required ('Selecione a unidade de medida'),

  price: Yup.number ().when (['use_in_service'], (use_in_service, schema) => {
    if (!use_in_service?.value)
      return schema.positive ('Insira o preço')
    return schema
  }),
    
  cost: Yup.number (),
  total_stock: Yup.number (),
  total_content: Yup.number (),
  comission_percentage: Yup.number ()
})

export default createProductValidator
