import React, { useState, useEffect } from 'react'
import cutileiApi from '../../../services/cutileiApi'
import DateInput from '../../../components/Inputs/DateInput'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { DateTime } from 'luxon'

import {
  SubContainer,
  Row,
  FilterColumn,
  TimeTablesGrid,
  Button,
  SubTitle,
  InfoText
} from '../styles'

function TimeTablesForm ({
  schedule = {},
  onDateTimeSelected: handleDateTimeSelected = () => null
}) {
  const now = DateTime.now ()
  const [times, setTimes] = useState ([])
  const [loadingDates, setLoadingDates] = useState (true)
  const [loadingTimes, setLoadingTimes] = useState (false)
  const [workingDays, setWorkingDays] = useState ([])
  const [message, setMessage] = useState (null)
  const [date, setDate] = useState (now.toJSDate ())
  const { business, service } = schedule
  
  useEffect (() => {
    getAvaliableDates ()
  }, [])

  useEffect (() => {
    getTimes (date)
  }, [date])

  const getAvaliableDates = async () => {
    setLoadingDates (true)

    const url = `/businesses/${business.id}/working_days?accepts_online=true`

    try {
      const { data: workingDays } = await cutileiApi.get (url)
      setWorkingDays (workingDays)
      await getTimes ()
    } catch (error) {
      console.log (error)
    } finally {
      setLoadingDates (false)
    }
  }

  const getTimes = async (selectedDate = date) => {
    setLoadingTimes (true)

    const isoDate = DateTime.fromJSDate (selectedDate).toISODate ()
    const filters = `date=${isoDate}&schedule_id=${schedule.id}`
                  + `&service_id=${service.id}&online_schedule=true`

    try {
      const { data } = await cutileiApi.get (
        `/businesses/${business.id}/free_schedules?${filters}`
      )

      setTimes (isoDate === now.toISODate () ? data.filter (timeWindow => (
        DateTime.fromFormat (timeWindow, 'H:mm') > now
      )) : data)
    } catch (error) {
      console.log (error)
      setMessage (error.response.data.message)
    } finally {
      setLoadingTimes (false)
    }
  }

  const getDisabledDates = ({ date }) => {
    const parsedDate = DateTime.fromJSDate (date)

    return (
      parsedDate < now.minus ({days: 1}) ||
      !workingDays.some (day => day.iso_number === parsedDate.weekday)
    )
  }

  return (
    <SubContainer>
      <SubTitle>Escolha data e horário</SubTitle>
      <Row style={{marginTop: 10}}>
        <FilterColumn>
          <DateInput
            value={date}
            onChange={setDate}
            style={{width: '100%'}}
            disabledDates={getDisabledDates}
          />
        </FilterColumn>
      </Row>
      {loadingDates || loadingTimes ? <Loading style={{marginTop: 20}}/> : (
        message ? (
          <InfoText style={{marginTop: 10}}>
            {message}
          </InfoText>
        ) : times.length > 0 ? (
          <TimeTablesGrid>
            {times.map ((time, index) => (
              <Button key={index} onClick={() => handleDateTimeSelected (date, time)}>
                {time}
              </Button>
            ))}
          </TimeTablesGrid>
        ) : (
          <InfoText style={{marginTop: 10}}>
            Não há horários disponíveis neste dia
          </InfoText>
        )
      )}
    </SubContainer>
  )
}

export default TimeTablesForm
