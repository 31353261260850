import React, { useState } from 'react'
import BusinessService from './BusinessService'
import EditServiceModal from '../../../pages/Modals/EditServiceModal'

import {
  Container,
  SubTitle,
  InfoText,
  LabelRow
} from './styles'

function BusinessServiceList ({
  services,
  weekdays,
  businessProducts = [],
  professionalID,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  const [serviceData, setServiceData] = useState (null)
  const [showEditServiceModal, setShowEditServiceModal] = useState (false)
  
  const editService = service => {
    setServiceData (service)
    toggleEditServiceModal ()
  }

  const toggleEditServiceModal = () => setShowEditServiceModal (!showEditServiceModal) 

  return (
    <Container>
      {services.length > 0 ? (
        <>
          <LabelRow>
            <SubTitle>Nome</SubTitle>
            <SubTitle>Duração</SubTitle>
            <SubTitle>Tolerância</SubTitle>
            <SubTitle>Comissão</SubTitle>
            <SubTitle>Preço</SubTitle>
            <SubTitle>Ações</SubTitle>
          </LabelRow>
          {services.map (service =>
            <BusinessService
              key={service.id}
              data={service}
              weekdays={weekdays}
              professionalID={professionalID}
              onEdit={editService}
              onEditPromotion={handleEdit}
              onDelete={handleDelete}
            />
          )}
          {serviceData && (
            <EditServiceModal
              visible={showEditServiceModal}
              data={serviceData}
              weekdays={weekdays}
              businessProducts={businessProducts}
              professionalID={professionalID}
              onClose={toggleEditServiceModal}
              onConfirm={handleEdit}
            />
          )}
        </>
      ) : (
        <InfoText>Você ainda não possui serviços cadastrados</InfoText>
      )}
      
    </Container>
  )
}

export default BusinessServiceList
