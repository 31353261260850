export default class Product {
  static USE_OPTIONS = [
    {label: 'Venda', value: false},
    {label: 'Uso em serviços', value: true}
  ]

  static UNIT_OPTIONS = [
    {label: 'Unidade (un)', value: 'un'},
    {label: 'Mililitros (ml)', value: 'ml'},
    {label: 'Miligramas (mg)', value: 'mg'}
  ]
}
