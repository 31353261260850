import React from 'react'
import { siteUrl } from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import CopyButton from '../../../components/CopyButton'
import Modal from 'react-modal'
import { FaTimes } from 'react-icons/fa'

import {
  modalStyle,
  Container,
  Header,
  Row,
  Title,
  InfoText,
  CloseButton,
  LinkText
} from './styles'

function ScheduleLinkModal ({
  visible,
  onClose: handleClose
}) {
  const businessId = AuthService.getBusinessId ()
  const businessUrl = `${siteUrl}/businesses/${businessId}/schedule`

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Container>
        <Header>
          <Title>Agendamento por link</Title>
          <CloseButton onClick={handleClose}>
            <FaTimes color='#FF3939' size={18}/>
          </CloseButton>
        </Header>
        <InfoText style={{marginBottom: 10}}>
          Você pode copiar seu link de agendamentos e colocar em suas redes sociais ou enviar para seus clientes.
        </InfoText>
        <InfoText>
          Através desse link, seus clientes vão poder agendar serviços em seu salão.
        </InfoText>
        <Row>
          <LinkText to={`/businesses/${businessId}/schedule`}>
            {businessUrl}
          </LinkText>
          <CopyButton text={businessUrl}/>
        </Row>
      </Container>
    </Modal>
  )
}

export default ScheduleLinkModal
