import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../services/auth'
import ScheduleService from '../../../services/Schedule'
import cutileiApi from '../../../services/cutileiApi'
import Select from '../../../components/Inputs/Select'
import MoneyInput from '../../../components/Inputs/MoneyInput'
import NumberInput from '../../../components/Inputs/NumberInput'
import CheckBox from '../../../components/CheckBox'
import { Formik } from 'formik'
import createServiceValidator from '../../../validators/createServiceValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import ReactTooltip from 'react-tooltip'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Form,
  FormField,
  ProductGrid,
  Title,
  Input,
  Button,
  CloseButton,
  SmallButton,
  DangerButton,
  Row,
  Label,
  SubTitle,
  InfoText,
  WarningText,
  ErrorContainer
} from './styles'

function AddServiceModal ({
  visible,
  weekdays = [],
  businessProducts = [],
  serviceCategories,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const [addPromotion, setAddPromotion] = useState (false)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const calculateComissionValue = (price, comissionPercentage) => {
    return Math.round (price * (comissionPercentage / 100 || 0))
  }

  const calculateComissionPercentage = (price, comissionValue) => {
    return Math.round ((comissionValue / price) * 100)
  }

  const recalcutaleComissionValue = (values, setFieldValue) => {
    const comissionValue = calculateComissionValue (values.price, values.comission_percentage)
    setFieldValue ('professional_comission', comissionValue)
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Formik
        validationSchema={createServiceValidator}
        initialValues={{
          'name': '',
          'category': null,
          'duration': 15,
          'max_delay': 15,
          'price': 0,
          'comission_percentage': 40,
          'professional_comission': 0,
          'business_comission': 0,
          'accepts_online': false,
          'promotion_weekdays': weekdays.map (day => ({
            ...day, price: 0, selected: false
          })),
          'products': []
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const { category, comission_percentage, ...serviceData } = values
          let promotion = null

          try {
            const { data: service } = await cutileiApi.post ('/services', {
              serviceCategoryId: category.value,
              businessId: businessId,
              comission_percentage: comission_percentage / 100,
              ...serviceData
            }, requestConfig)

            if (addPromotion) {
              const { data: promotionData } = await cutileiApi.post (`/businesses/${businessId}/promotions`, {
                serviceId: service.id,
                weekdays: values.promotion_weekdays.filter (day => day.selected)
              }, requestConfig)

              promotion = promotionData
            }

            setSubmitting (false)
            handleConfirm ({
              ...service,
              products: values.products,
              promotions: [promotion]
            })
            handleClose ()
          } catch (error) {
            setSubmitting (false)
            setErrorMessage (error.response?.data.message)
            console.log (error)
          }
        }}
      >
        {function AddServiceForm ({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          handleSubmit
        }) {
          useEffect (() => {
            recalcutaleComissionValue (values, setFieldValue)
          }, [values.price])

          useEffect (() => {
            setFieldValue ('business_comission', Math.abs (values.price - values.professional_comission))
          }, [values.price, values.comission_percentage, values.professional_comission])

          const handleAddProduct = () => {
            setFieldValue ('products', [
              ...values.products, {
                id: '',
                name: '',
                quantity_used: 1
              }
            ])
          }

          const handleDeleteProduct = index => {
            setFieldValue (
              'products',
              values.products.map ((p, i) => ({...p, idx: i})).filter (s => s.idx !== index)
            )
          }

          return (
            <Form onSubmit={handleSubmit}>
              <Title>Cadastrar serviço</Title>
              <CloseButton onClick={handleClose}>
                <FA.FaTimes color='#FF3939' size={18}/>
              </CloseButton>
              <Row>
                <FormField>
                  <Input 
                    placeholder='Nome'
                    value={values.name}
                    onChange={handleChange ('name')} 
                    onBlur={handleBlur ('name')}
                  />
                  {touched.name && errors.name && (
                    <WarningText>
                      {errors.name}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Select
                    name='category'
                    placeholder='Categoria...'
                    value={values.category}
                    options={serviceCategories}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.category}
                    touched={touched.category}
                  />
                  {touched.category && errors.category && (
                    <WarningText>
                      {errors.category}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <Label htmlFor='duration'>
                    Tempo do serviço (mins) {values.duration >= 60 && ` - ${
                      ScheduleService.getFormattedDuration (values.duration)
                    }`}
                  </Label>
                  <NumberInput
                    id='duration'
                    name='duration'
                    value={values.duration}
                    minValue={15}
                    stepOnly={true}
                    step={values.duration < 60 ? 15 : 30}
                    onChange={(name, value) => {
                      const newValue = values.duration === 60 && values.duration > value ? 45 : value
                      setFieldValue (name, newValue)
                    }}
                    style={{width: '100%'}}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='max_delay'>
                    Tolerância de atraso (mins)
                  </Label>
                  <NumberInput
                    id='max_delay'
                    name='max_delay'
                    value={values.max_delay}
                    minValue={5}
                    maxValue={60}
                    onChange={setFieldValue}
                    style={{width: '100%'}}
                    data-for='max_delay_tooltip'
                    data-tip
                  />
                  <ReactTooltip
                    id='max_delay_tooltip'
                    effect='solid'
                    place='bottom' 
                    backgroundColor='#252525'
                    tooltipRadius='10'
                  >
                    <Label style={{color: '#FFFFFF', width: 200}}>
                      A tolerância de atraso para serviços pode ser configurada entre 5 e 20 minutos.
                    </Label>
                  </ReactTooltip>
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <Label htmlFor='price'>
                    Preço (R$)
                  </Label>
                  <MoneyInput
                    id='price'
                    name='price'
                    placeholder='Preço'
                    value={values.price}
                    onValueChange={setFieldValue}
                    onBlur={handleBlur ('price')}
                  />
                  {touched.price && errors.price && (
                    <WarningText>
                      {errors.price}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Label htmlFor='comission_percentage'>
                    Comissão (%)
                  </Label>
                  <NumberInput
                    id='comission_percentage'
                    name='comission_percentage'
                    placeholder='Comissão (%)'
                    value={values.comission_percentage}
                    minValue={0}
                    maxValue={100}
                    onChange={(name, value) => {
                      setFieldValue (name, value)
                      setFieldValue ('professional_comission', calculateComissionValue (values.price, value))
                    }}
                    onBlur={handleBlur}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='professional_comission'>
                    Valor (profissional)
                  </Label>
                  <MoneyInput
                    id='professional_comission'
                    name='professional_comission'
                    placeholder='Valor líquido'
                    value={values.professional_comission}
                    maxValue={values.price}
                    onValueChange={(name, value) => {
                      setFieldValue (name, value)
                      setFieldValue ('comission_percentage', calculateComissionPercentage (values.price, value))
                    }}
                    onBlur={handleBlur ('professional_comission')}
                    style={{color: '#44CF6C', fontSize: 16, fontWeight: 'bold'}}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='business_comission'>
                    Valor (salão)
                  </Label>
                  <MoneyInput
                    id='business_comission'
                    name='business_comission'
                    value={values.business_comission}
                    style={{color: '#44CF6C', fontSize: 16, fontWeight: 'bold'}}
                    disabled
                  />
                </FormField>
              </Row>
              <FormField>
                <Row style={{alignItems: 'center', marginBottom: 7}}>
                  <CheckBox
                    id='accepts_online'
                    style={{marginRight: -3}}
                    value={values.accepts_online}
                    onValueChange={value => setFieldValue ('accepts_online', value)}
                  />
                  <Label htmlFor='accepts_online' style={{margin: 0}}>
                    Permitir agendamento por link
                  </Label>
                </Row>
              </FormField>
              <FormField>
                <Row style={{alignItems: 'center', marginBottom: 10}}>
                  <CheckBox
                    id='add_promotion'
                    style={{marginRight: -3}}
                    value={addPromotion}
                    onValueChange={setAddPromotion}
                  />
                  <Label htmlFor='add_promotion' style={{margin: 0}}>
                    Adicionar promoção
                  </Label>
                </Row>
              </FormField>
              {addPromotion && (
                <FormField>
                  <InfoText style={{width: 500}}>
                    Selecione os dias da semana em que deseja configurar um preço promocional para este serviço. Dias não selecionados permanecerão com o preço padrão.
                  </InfoText>
                  <Row>
                    {weekdays.map ((day, index) => (
                      <FormField key={day.id}>
                        <Row style={{marginBottom: 3, alignItems: 'center'}}>
                          <CheckBox
                            id={`promotion_weekdays.${index}.selected`}
                            style={{marginRight: -3}}
                            value={values.promotion_weekdays[index].selected}
                            onValueChange={value => {
                              if (value === true)
                                setFieldValue (`promotion_weekdays.${index}.price`, values.price)
                              setFieldValue (`promotion_weekdays.${index}.selected`, value)
                            }}
                          />
                          <Label htmlFor={`promotion_weekdays.${index}.selected`} style={{margin: 0}}>
                            {day.name.substring (0, 3)}
                          </Label>
                        </Row>
                        {values.promotion_weekdays[index].selected && (
                          <MoneyInput
                            id={`promotion_weekdays.${index}.price`}
                            name={`promotion_weekdays.${index}.price`}
                            placeholder='Preço'
                            value={values.promotion_weekdays[index].price}
                            onValueChange={setFieldValue}
                            onBlur={handleBlur (`promotion_weekdays.${index}.price`)}
                          />
                        )}
                      </FormField>
                    ))}
                  </Row>
                </FormField>
              )}
              {values.products.length > 0 && values.products.map ((product, index) => (
                <ProductGrid key={index}>
                  <FormField>
                    <Label>Produto</Label>
                    <Select
                      name={`products.${index}.id`}
                      placeholder='Selecionar...'
                      value={product.id ? {
                        label: product.nickname || product.name,
                        value: product.id
                      } : null}
                      options={businessProducts}
                      onChange={(name, option) => {
                        setFieldValue (name, option.value.id)
                        setFieldValue (`products.${index}.name`, option.value.nickname || option.value.name)
                      }}
                      onBlur={setFieldTouched}
                      error={errors.products?.[index]?.name}
                      touched={touched.products?.[index]?.name}
                    />
                    {touched.products?.[index]?.name && errors.products?.[index]?.name && (
                      <WarningText>
                        {errors.products?.[index]?.name}
                      </WarningText>
                    )}
                  </FormField>
                  <Row style={{margin: 0}}>
                    <FormField>
                      <Label>Qtd. utilizada</Label>
                      <NumberInput
                        name={`products.${index}.quantity_used`}
                        value={product.quantity_used}
                        minValue={1}
                        maxValue={999999}
                        onChange={setFieldValue}
                      />
                    </FormField>
                    <InfoText style={{alignSelf: 'flex-end', marginBottom: 9}}>
                      {businessProducts.find (p => p.value.id === product.id)?.value.content_unit}
                    </InfoText>
                  </Row>
                  <DangerButton onClick={() => handleDeleteProduct (index)}>
                    <FA.FaTrash color='#FFFFFF' size={12}/>
                  </DangerButton>
                </ProductGrid>
              ))}
              <SmallButton onClick={handleAddProduct}>
                <FA.FaPlus size={12}/>
                <SubTitle>Adicionar produto</SubTitle>
              </SmallButton>
              {errorMessage && (
                <ErrorContainer>
                  {errorMessage}
                </ErrorContainer>
              )}
              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? <ButtonLoading/> : 'Criar serviço'}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default AddServiceModal
