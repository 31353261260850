import React from 'react'
import ScheduleService from '../../../services/Schedule'
import Logo from '../../../icons/logo-cutilei-simple.png'
import NumberInput from '../../Inputs/NumberInput'
import MoneyInput from '../../Inputs/MoneyInput'
import Select from '../../Inputs/Select'
import CheckBox from '../../CheckBox'
import ReactTooltip from 'react-tooltip'
import * as FA from 'react-icons/fa'

import {
  Container,
  DataGrid,
  FormGrid,
  ProductGrid,
  FormField,
  Row,
  Image,
  Input,
  SmallButton,
  DangerButton,
  Label,
  TooltipLabel,
  SubTitle,
  InfoText,
  WarningText
} from './styles'

function ServiceForm ({
  data: service,
  businessProducts = [],
  professionalID = null,
  index,
  formikProps
}) {
  const {
    values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur
  } = formikProps
  const selected = values.services[index]?.selected
  const serviceProducts = values.services[index]?.products ?? []

  const handleAddProduct = () => {
    setFieldValue (`services.${index}.products`, [
      ...values.services[index]?.products,
      {
        id: '',
        name: '',
        quantity_used: 1
      }
    ])
  }

  const handleDeleteProduct = productIndex => {
    setFieldValue (
      `services.${index}.products`,
      values.services[index]?.products.map ((p, i) => ({
        ...p, idx: i
      })).filter (s => s.idx !== productIndex)
    )
  }

  return (
    <Container selected={selected}>
      <DataGrid onClick={() => setFieldValue (`services.${index}.selected`, !selected)}>
        <Row>
          <CheckBox
            id={`services.${index}.selected`}
            value={values.services[index]?.selected}
            onValueChange={value => setFieldValue (`services.${index}.selected`, value)}
          />
          <Row>
            {service.cutilei_service && <Image src={Logo} alt='Service Cutilei'/>}
            <SubTitle>
              {service.name}
            </SubTitle>
          </Row>
        </Row>
        <InfoText>
          {service.category.name}
        </InfoText>
      </DataGrid>
      {selected && (
        <FormField>
          <FormGrid>
            <FormField>
              <Label>Apelido</Label>
              <Input
                placeholder='Apelido (opcional)'
                value={values.services[index]?.nickname}
                onChange={handleChange (`services.${index}.nickname`)} 
                onBlur={handleBlur (`services.${index}.nickname`)}
                style={{marginTop: 0}}
              />
            </FormField>
            <FormField>
              <Label>Preço (R$)</Label>
              <MoneyInput
                name={`services.${index}.price`}
                placeholder='Preço'
                value={values.services[index]?.price}
                onValueChange={setFieldValue}
                onBlur={handleBlur (`services.${index}.price`)}
              />
              {touched.services?.[index]?.price && errors.services?.[index]?.price && (
                <WarningText>
                  {errors.services[index]?.price}
                </WarningText>
              )}
            </FormField>
            <FormField>
              <Label>Comissão (%)</Label>
              <NumberInput
                name={`services.${index}.comission_percentage`}
                value={values.services[index]?.comission_percentage}
                minValue={values.services[index]?.cutilei_service ? 40 : 0}
                maxValue={100}
                onChange={setFieldValue}
              />
            </FormField>
            <FormField>
              <Label htmlFor='duration'>
                Tempo do serviço (mins) {values.services[index]?.duration >= 60 && ` - ${
                  ScheduleService.getFormattedDuration (values.services[index]?.duration)
                }`}
              </Label>
              <NumberInput
                name={`services.${index}.duration`}
                value={values.services[index]?.duration}
                step={values.services[index]?.duration < 60 ? 15 : 30}
                stepOnly={true}
                onChange={(name, value) => {
                  const currentValue = values.services[index]?.duration
                  const newValue = currentValue === 60 && currentValue > value ? 45 : value
                  setFieldValue (name, newValue)
                }}
              />
            </FormField>
            <FormField>
              <Label>Tolerância de atraso (mins)</Label>
              <NumberInput
                name={`services.${index}.max_delay`}
                value={values.services[index]?.max_delay}
                minValue={5}
                maxValue={20}
                onChange={setFieldValue}
                data-for={`services.${index}.max_delay`}
                data-tip
              />
              <ReactTooltip
                id={`services.${index}.max_delay`}
                effect='solid'
                place='bottom'
                backgroundColor='#252525'
                tooltipRadius='10'
              >
                <TooltipLabel style={{width: 200}}>
                  A tolerância de atraso para serviços pode ser configurada entre 5 e 20 minutos.
                </TooltipLabel>
              </ReactTooltip>
            </FormField>
          </FormGrid>
          <FormField>
            <Row style={{alignSelf: 'flex-start', marginTop: 5}}>
              <CheckBox
                id='accepts_online'
                style={{marginRight: -3}}
                value={values.services[index]?.accepts_online}
                onValueChange={value => setFieldValue (`services.${index}.accepts_online`, value)}
              />
              <Label htmlFor='accepts_online' style={{margin: 0}}>
                Permitir agendamento por link
              </Label>
            </Row>
          </FormField>
          {(!professionalID && serviceProducts.length > 0) && serviceProducts.map ((product, pIndex) => (
            <ProductGrid key={pIndex}>
              <FormField>
                <Label>Produto</Label>
                <Select
                  name={`services.${index}.products.${pIndex}.id`}
                  placeholder='Selecionar...'
                  value={product.id ? {
                    label: product.nickname || product.name,
                    value: product.id
                  } : null}
                  options={businessProducts}
                  onChange={(name, option) => {
                    setFieldValue (name, option.value.id)
                    setFieldValue (
                      `services.${index}.products.${pIndex}.name`,
                      option.value.nickname || option.value.name
                    )
                  }}
                  onBlur={setFieldTouched}
                  error={errors.services?.[index]?.products?.[pIndex]?.data}
                  touched={touched.services?.[index]?.products?.[pIndex]?.data}
                />
                {touched.services?.[index]?.products?.[pIndex]?.data
                && errors.services?.[index]?.products?.[pIndex]?.data && (
                  <WarningText>
                    {errors.services?.[index]?.products?.[pIndex]?.data}
                  </WarningText>
                )}
              </FormField>
              <Row>
                <FormField>
                  <Label>Qtd. utilizada</Label>
                  <NumberInput
                    name={`services.${index}.products.${pIndex}.quantity_used`}
                    value={product.quantity_used}
                    minValue={1}
                    maxValue={999999}
                    onChange={setFieldValue}
                  />
                </FormField>
                {product.id && (
                  <InfoText style={{marginTop: 18}}>
                    {businessProducts.find (p => p.value.id === product.id)?.value.content_unit}
                  </InfoText>
                )}
              </Row>
              <DangerButton onClick={() => handleDeleteProduct (pIndex)}>
                <FA.FaTrash color='#FFFFFF' size={12}/>
              </DangerButton>
            </ProductGrid>
          ))}
          {!professionalID && (
            <SmallButton onClick={handleAddProduct}>
              <FA.FaPlus size={12}/>
              <SubTitle>Adicionar produto</SubTitle>
            </SmallButton>
          )}
        </FormField>
      )}
    </Container>
  )
}

export default ServiceForm
