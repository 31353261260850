import styled from 'styled-components'

export const Container = styled.div`
  min-width: 840px;
  width: 1080px;
`

export const LabelRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr repeat(4, 2fr);
  gap: 10px;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin: 0px 10px;
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 14px;
  text-align: center;
`

export const Title = styled(InfoText)`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
`

export const SubTitle = styled(Title)`
  font-size: 14px;
  margin-bottom: 7px;
`
