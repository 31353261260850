import * as Yup from 'yup'

const phoneRegex = /\(|\)| |-/g

const scheduleCustomerValidator = Yup.object ({
  firstName: Yup.string ()
    .required ('Digite seu nome')
    .min (3, 'O nome deve conter no minimo 3 caracteres')
    .max (64, 'O nome deve conter até 64 caracteres'),

  lastName: Yup.string ()
    .required ('Digite seu sobrenome')
    .min (3, 'O sobrenome deve conter no minimo 3 caracteres')
    .max (64, 'O sobrenome deve conter até 64 caracteres'),

  email: Yup.string ()
    .required ('Digite seu e-mail')
    .email ('Insira um email válido')
    .max (128, 'O e-mail deve conter até 128 caracteres'),
    
  phone: Yup.string ()
    .required ('Digite seu celular')
    .test ('validPhone', 'Insira um número válido', function (value) {
      if (!value) return true
      return value.replace (phoneRegex, '').length === 11
    }),

  birth: Yup.string ()
    .required ('Digite sua data de nascimento')
    .min (8, 'Digite uma data válida (dd/mm/aaaa)'),
})

export default scheduleCustomerValidator
