import styled from 'styled-components'

export const Button = styled.button`
  background-color: #252525;
  color: #F7F7F7;
  min-width: 120px;
  height: 40px;
  padding: 8px 10px;
  border-radius: 15px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  font-size: 14px;
  border: none;
  gap: 10px;

  &:hover {
    background-color: #252525;
    color: #F7F7F7;
  }
`
