import React, { useState } from 'react'
import Calendar from 'react-calendar'
import Modal from 'react-modal'
import { DateTime } from 'luxon'
import * as IO from 'react-icons/io5'

import {
  modalStyle,
  Container,
  CalendarContainer,
  InfoText,
  Button
} from './styles'

export default function DateInput ({
  value: dateValue,
  onChange,
  style = {},
  selectRange = false,
  disabledDates = () => null
}) {
  const initialDate = Array.isArray (dateValue) ? dateValue : [dateValue, dateValue]

  const [showCalendar, setShowCalendar] = useState (false)
  const [startDate, setStartDate] = useState (
    DateTime.fromJSDate (initialDate[0]).toFormat ('dd/MM/yyyy')
  )
  const [endDate, setEndDate] = useState (
    DateTime.fromJSDate (initialDate[1]).toFormat ('dd/MM/yyyy')
  )

  const handleChange = selectedDate => {
    if (selectRange) {
      setStartDate (DateTime.fromJSDate (selectedDate[0]).toFormat ('dd/MM/yyyy'))
      setEndDate (DateTime.fromJSDate (selectedDate[1]).toFormat ('dd/MM/yyyy'))
      onChange (selectedDate)
    } else {
      const formattedDate = DateTime.fromJSDate (selectedDate).toFormat ('dd/MM/yyyy')
      setStartDate (formattedDate)
      setEndDate (formattedDate)
      onChange (selectedDate)
    }
    toggleCalendar ()
  }

  const toggleCalendar = () => setShowCalendar (!showCalendar)

  return ( 
    <Container style={style}>
      <Button type='button' onClick={toggleCalendar}>
        <IO.IoCalendar size={16}/>
        <InfoText>
          {selectRange ? `${startDate} - ${endDate}` : startDate}
        </InfoText>
      </Button>
      <Modal
        isOpen={showCalendar}
        onRequestClose={toggleCalendar}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={modalStyle}
      >
        <CalendarContainer>
          <Calendar
            value={dateValue}
            selectRange={selectRange}
            calendarType='US'
            returnValue={selectRange ? 'range' : 'start'}
            tileDisabled={disabledDates}
            onChange={handleChange}
          />
        </CalendarContainer>
      </Modal>
    </Container>
  )
}
