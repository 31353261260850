import * as Yup from 'yup'

const serviceValidator = Yup.object ({
  services: Yup.array ().of (Yup.object ().shape ({
    id: Yup.string (),
    selected: Yup.boolean (),
    nickname: Yup.string (),
    duration: Yup.number (),
    max_delay: Yup.number (),
    comission_percentage: Yup.number (),
    price: Yup.number ().nullable ().when ('selected', {
      is: true,
      then: schema => schema.positive ('Insira o preço')
    }),
    products: Yup.array ().of (Yup.object ().shape ({
      id: Yup.string ().required ('Selecione o produto'),
      name: Yup.string ().required ('Selecione o produto'),
      quantity_used: Yup.number ()
    })),
  }))
})

export default serviceValidator
