import * as Yup from 'yup'

const businessProductValidator = Yup.object ({
  products: Yup.array ().of (Yup.object ().shape ({
    id: Yup.string (),
    selected: Yup.boolean (),
    nickname: Yup.string (),
    total_stock: Yup.number (),
    total_content: Yup.number (),
    use_in_service: Yup.object ().nullable ().required ('Selecione a finalidade'),
    content_unit: Yup.object ().nullable ().required ('Selecione a unidade de medida'),
    comission_percentage: Yup.number (),
    cost: Yup.number (),
    price: Yup.number ().when (['selected', 'use_in_service'], (selected, use_in_service, schema) => {
      if (selected && !use_in_service?.value)
        return schema.positive ('Insira o preço')
      return schema
    }),
  }))
})

export default businessProductValidator
