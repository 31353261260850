import React, { useState, useEffect } from 'react'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import viaCepApi from '../../../services/viaCepApi'
import MaskedInputService from '../../../services/maskedInput'
import ChangePictureModal from '../../Modals/ChangePictureModal'
import AlertDialog from '../../../components/AlertDialog'
import { Formik } from 'formik'
import editBusinessValidator from '../../../validators/editBusinessValidator'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import { FaStar } from 'react-icons/fa'

import {
  Container,
  Form,
  Button,
  BigImage,
  ImageContainer,
  RatingContainer,
  SubTitle,
  LinkText,
  FormField,
  WarningText,
  ErrorContainer,
  Input,
  MaskedInput,
  Row
} from '../styles'

function EditBusiness () {
  require ('cleave.js/dist/addons/cleave-phone.br')

  const [loading, setLoading] = useState (true)
  const [loadingAddress, setLoadingAddress] = useState (false)
  const [business, setBusiness] = useState (null)
  const [socialMedias, setSocialMedias] = useState ([])
  const [businessSocialMedias, setBusinessSocialMedias] = useState ([])
  const [addressFound, setAddressFound] = useState (false)
  const [errorMessage, setErrorMessage] = useState (null)
  const [showPictureDialog, setShowPictureDialog] = useState (false)
  const [showOkDialog, setShowOkDialog] = useState (false)
  const token = AuthService.getToken ()
  const user = AuthService.getUser ()
  const address = business?.addresses[0]

  useEffect (() => {
    getData ()
  }, [])

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const getData = async () => {
    try {
      const { data: business } = await cutileiApi.get (`/businesses/${user.id}`)
      const { data: socialMedias } = await cutileiApi.get ('/social_medias')
      const { data: businessSocialMedias } = await cutileiApi.get (`/businesses/${business.id}/social_medias`)

      setBusiness (business)
      setSocialMedias (socialMedias)
      setBusinessSocialMedias (businessSocialMedias)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const searchAddress = async (event, setFieldValue) => {
    const postal_code = event.target.rawValue

    setFieldValue ('postal_code', postal_code)
    
    if (postal_code.length === 8) {
      setLoadingAddress (true)
      try {
        const response = await viaCepApi.get (`/ws/${postal_code}/json`)
        const { data } = response

        setFieldValue ('route', data.logradouro)
        setFieldValue ('district', data.bairro)
        setFieldValue ('city', data.localidade)
        setFieldValue ('state', data.uf)

        setAddressFound (true)
      } catch (error) {
        console.log (error.response)
      } finally {
        setLoadingAddress (false)
      }
    }
  }

  const handleChangePicture = newPictureUrl => {
    AuthService.updateUser ({business: {...user, logo: newPictureUrl}})
    setBusiness ({...business, logo: newPictureUrl})
    togglePictureDialog ()
  }

  const togglePictureDialog = () => setShowPictureDialog (!showPictureDialog)
  const toggleOkDialog = () => setShowOkDialog (!showOkDialog)

  return (
    <Container>
      {loading ? <Loading/> : (
        <Formik
          validationSchema={editBusinessValidator}
          initialValues={{
            'name': business.name,
            'corporate_name': business.corporate_name,
            'email': business.email,
            'cnpj': business.cnpj,
            'cellphone': business.cellphone,
            'phone': business.phone,
            'owner': business.owner,
            'owner_email': business.owner_email,
            'owner_phone': business.owner_phone,
            'id': address.id,
            'route': address.route,
            'number': address.number,
            'complement': address.complement,
            'district': address.district,
            'city': address.city,
            'state': address.state,
            'postal_code': address.postal_code,
            'social_medias': socialMedias.map (socialMedia => ({
              id: socialMedia.id,
              name: socialMedia.name,
              link: businessSocialMedias.some (businessSocialMedia => (
                businessSocialMedia.id === socialMedia.id
              )) ? businessSocialMedias.find (businessSocialMedia => 
                businessSocialMedia.id === socialMedia.id
              ).link : ''
            }))
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const {
              name,
              corporate_name,
              owner,
              owner_email,
              owner_phone,
              email,
              cnpj,
              phone,
              cellphone,
              social_medias: _social_medias,
              ...address
            } = values

            setErrorMessage (null)
            setSubmitting (true)

            const social_medias = _social_medias.filter (socialMedia => (
              socialMedia.link !== ''
            ))

            try {
              await cutileiApi.put (`/businesses/${business.id}`, {
                name,
                corporate_name,
                owner,
                owner_email,
                owner_phone,
                email,
                cnpj,
                phone,
                cellphone,
                address
              }, requestConfig)

              await cutileiApi.post (
                `/businesses/${business.id}/social_medias`,
                {social_medias},
                requestConfig
              )

              setSubmitting (false)
              toggleOkDialog ()
            } catch (error) {
              setSubmitting (false)
              console.log (error.response)
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit}>
              <ImageContainer onClick={togglePictureDialog}>
                <BigImage src={business.logo}/>
                {business?.ratings_count > 0 && (
                  <RatingContainer>
                    <FaStar size={16} color='#FFC100'/>
                    <SubTitle>
                      {business?.average_rating}
                    </SubTitle>
                  </RatingContainer>
                )}
              </ImageContainer>
              <Row>
                <LinkText to={business?.ratings_count === 0 ? '#' :'/business/ratings'}>
                  {`${business?.ratings_count} ${business?.ratings_count === 1 ? 'avaliação' : 'avaliações'}`}
                </LinkText>
              </Row>
              <SubTitle>Dados do Salão</SubTitle>
              <Row>
                <FormField stretch={2}>
                  <Input
                    placeholder='Nome fantasia'
                    value={values.name}
                    onChange={handleChange ('name')}
                    onBlur={handleBlur ('name')}
                  />
                  {touched.name && errors.name && (
                    <WarningText>{ errors.name }</WarningText>
                  )}
                </FormField>
                <FormField stretch={2}>
                  <Input
                    placeholder='Razão social'
                    value={values.corporate_name}
                    onChange={handleChange ('corporate_name')}
                    onBlur={handleBlur ('corporate_name')}
                  />
                  {touched.corporate_name && errors.corporate_name && (
                    <WarningText>{ errors.corporate_name }</WarningText>
                  )}
                </FormField>
                <FormField stretch={2}>
                  <MaskedInput
                    name='cnpj'
                    placeholder='CNPJ'
                    options={{
                      blocks: [2,3,3,4,2],
                      delimiters: ['.', '.','/', '-'],
                      numericOnly: true
                    }}
                    value={values.cnpj}
                    onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                    onBlur={handleBlur ('cnpj')}
                  />
                  {touched.cnpj && errors.cnpj && (
                    <WarningText>{ errors.cnpj }</WarningText>
                  )}
                </FormField>
              </Row>
              <Row>
                <FormField stretch={2}>
                  <Input
                    type='email'
                    placeholder='E-mail'
                    value={values.email}
                    onChange={handleChange ('email')}
                    onBlur={handleBlur ('email')}
                  />
                  {touched.email && errors.email && (
                    <WarningText>{ errors.email }</WarningText>
                  )}
                </FormField>
                <FormField>
                  <MaskedInput
                    name='cellphone'
                    placeholder='Celular'
                    options={{
                      blocks: [0, 2, 5, 4],
                      delimiters: ['(', ') ', '-'],
                      numericOnly: true,
                    }}
                    value={values.cellphone}
                    onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                    onBlur={handleBlur ('cellphone')}
                  />
                  {touched.cellphone && errors.cellphone && (
                    <WarningText>{ errors.cellphone }</WarningText>
                  )}
                </FormField>
                <FormField>
                  <MaskedInput
                    name='phone'
                    placeholder='Telefone fixo (opcional)'
                    options={{
                      blocks: [0, 2, 4, 4],
                      delimiters: ['(', ') ', '-'],
                      numericOnly: true,
                    }}
                    value={values.phone}
                    onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                    onBlur={handleBlur ('phone')}
                  />
                  {touched.phone && errors.phone && (
                    <WarningText>{ errors.phone }</WarningText>
                  )}
                </FormField>
              </Row>
              <SubTitle>Dados do responsável</SubTitle>
              <Row>
                <FormField>
                  <Input
                    placeholder='Nome completo do responsável'
                    value={values.owner}
                    onChange={handleChange ('owner')}
                    onBlur={handleBlur ('owner')}
                  />
                  {touched.owner && errors.owner && (
                    <WarningText>{ errors.owner }</WarningText>
                  )}
                </FormField>
                <FormField>
                  <Input
                    type='email'
                    placeholder='E-mail do responsável'
                    value={values.owner_email}
                    onChange={handleChange ('owner_email')}
                    onBlur={handleBlur ('owner_email')}
                  />
                  {touched.owner_email && errors.owner_email && (
                    <WarningText>{ errors.owner_email }</WarningText>
                  )}
                </FormField>
                <FormField>
                  <MaskedInput
                    name='owner_phone'
                    placeholder='Celular do responsável'
                    options={{
                      blocks: [0, 2, 5, 4],
                      delimiters: ['(', ') ', '-'],
                      numericOnly: true,
                    }}
                    value={values.owner_phone}
                    onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                    onBlur={handleBlur ('owner_phone')}
                  />
                  {touched.owner_phone && errors.owner_phone && (
                    <WarningText>{ errors.owner_phone }</WarningText>
                  )}
                </FormField>
              </Row>
              <SubTitle>Redes Sociais</SubTitle>
              <Row>
                {values.social_medias.map ((socialMedia, index) => (
                  <FormField key={socialMedia.id}>
                    <Input
                      name={`social_medias[${index}].link`}
                      placeholder={socialMedia.name}
                      value={values.social_medias[index].link}
                      onChange={event => setFieldValue (event.target.name, event.target.value)}
                      onBlur={() => null}
                    />
                  </FormField>
                ))}     
              </Row>
              <SubTitle>Endereço</SubTitle>
              <Row>
                <FormField>
                  <MaskedInput
                    name='postal_code'
                    placeholder='CEP'
                    options={{
                      blocks: [5,3],
                      delimiters: ['-'],
                      numericOnly: true
                    }}
                    value={values.postal_code}
                    onChange={event => searchAddress (event, setFieldValue)}
                    onBlur={handleBlur ('postal_code')}
                  />
                  {touched.postal_code && errors.postal_code && (
                    <WarningText>{ errors.postal_code }</WarningText>
                  )}
                </FormField>
                <FormField stretch={2}>
                  <Input
                    placeholder='Rua/avenida'
                    value={values.route}
                    onChange={handleChange ('route')}
                    onBlur={handleBlur ('route')}
                    disabled
                  />
                </FormField>
                <FormField>
                  <Input
                    placeholder='Número'
                    value={values.number}
                    onChange={handleChange ('number')}
                    onBlur={handleBlur ('number')}
                  />
                  {touched.number && errors.number && (
                    <WarningText>{ errors.number }</WarningText>
                  )}
                </FormField>
                <FormField>
                  <Input
                    placeholder='Complemento'
                    value={values.complement}
                    onChange={handleChange ('complement')}
                    onBlur={handleBlur ('complement')}
                  />
                </FormField>
              </Row>
              <Row>
                <FormField stretch={2}>
                  <Input
                    placeholder='Bairro'
                    value={values.district}
                    onChange={handleChange ('district')}
                    onBlur={handleBlur ('district')}
                    disabled
                  />
                </FormField>
                <FormField>
                  <Input
                    placeholder='Cidade'
                    value={values.city}
                    onChange={handleChange ('city')}
                    onBlur={handleBlur ('city')}
                    disabled
                  />
                </FormField>
                <FormField>
                  <Input
                    placeholder='Estado'
                    value={values.state}
                    onBlur={handleBlur ('state')}
                    disabled
                  />
                </FormField>
              </Row>
              {errorMessage && (
                <ErrorContainer>
                  <p>{errorMessage}</p>
                </ErrorContainer>
              )}
              <AlertDialog
                visible={showOkDialog}
                title='Sucesso'
                message='Alterações salvas!'
                confirmText='Ok'
                onConfirm={toggleOkDialog}
                onClose={toggleOkDialog}
              />
              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? <ButtonLoading/> : 'Salvar alterações'}
              </Button>
            </Form>
          )}
        </Formik>
      )}
      <ChangePictureModal
        title='Alterar foto de perfil'
        visible={showPictureDialog}
        onConfirm={handleChangePicture}
        onClose={togglePictureDialog}
      />
    </Container>
  )
}

export default EditBusiness
