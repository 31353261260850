import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px 10px 50px;
`

export const Row = styled(Container)`
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  margin-bottom: 10px;
  z-index: 99;
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 90vw;
  padding: 5px 10px 10px;
`

export const Header = styled.div`
  display: block;
  position: fixed;
  top: 0;
  background-color: #FFFFFF;
  width: 100%;
  height: 90px;
  z-index: 2;
`

export const LogoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
`

export const RatingContainer = styled.span`
  display: flex;
  z-index: 6;
  position: absolute;
  top: -8px;
  right: -8px;
  height: 18px;
  padding: 0px 6px 0px 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border: 1px solid #EEEEEE;
  border-radius: 15px;
  background-color: #FFFFFF;

  h3 {
    font-size: 12px;
    line-height: 20px;
    font-weight: bold;
    text-align: center;
    margin: 0px;
  }
`

export const CalendarContainer = styled(Container)`
  position: sticky;
  align-items: center;
  top: 16px;
  padding: 0px;
  margin: 0px;
  z-index: 4;
`

export const Schedules = styled.div`
  padding: 0px 20px;
  display: flex;
  justify-content: flex-start;
`

export const ImageButton = styled.button`
  position: relative;
  width: 55px;
  height: 55px;
  box-shadow: 0px 2px 10px -6px #252525;
  padding: 0px;
  border: 0px;
  border-radius: 10px;

  &:hover {
    box-shadow: 0px 2px 10px -4px #252525;
  }
`

export const Image = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 10px;
`

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 6px;
  align-self: center;
  z-index: 4;
`

export const SubTitle = styled.h3`
  font-size: 15px;
  font-weight: 500;
  margin: 0px;
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #252525;
  color: #F7F7F7;
  width: 100%;
  height: 38px;
  border: 0px;
  border-radius: 15px;
  text-decoration: none;
  margin-top: 10px;
  font-size: 14px;
  gap: 10px;

  &:hover {
    background-color: #252525;
    color: #F7F7F7;
  }
`

export const SmallButton = styled(Button)`
  color: #FFFFFF;
  background-color: #606060;
  width: 56px;
  height: 39px;
  margin: 0px;
  border-radius: 15px;
  padding: 0px 20px;

  &:hover {
    background-color: #404040;
  }
`

export const LinkButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #252525;
  color: #F7F7F7;
  width: 100%;
  height: 38px;
  border-radius: 15px;
  text-decoration: none;
  margin-top: 10px;
  font-size: 14px;
  gap: 8px;

  &:hover {
    background-color: #252525;
    color: #F7F7F7;
  }
`

export const LinkText = styled(Link)`
  text-decoration: none;
  font-size: 16px;
`

export const Label = styled.p`
  font-size: 16px;
  align-self: center;
  margin-top: 10px;
`

export const TooltipLabel = styled.label`
  text-align: center;
  color: #FFFFFF;
  margin: 0px;
`
