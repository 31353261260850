import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`

export const SubContainer = styled(Container)`
  width: 100%;
  padding: 0px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`

export const FilterColumn = styled(Column)`
  padding: 0;
  width: 250px;

  @media (max-width: 576px) {
    width: 100%;
  }
`

export const Row = styled(Container)`
  width: 100%;
  padding: 0px;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
`

export const LogoRow = styled(Row)`
  gap: 5px;
  margin-bottom: 12px;

  p {
    font-size: 16px;
  }
`

export const SchedulesGrid = styled.div`
  display: grid;
  gap: 15px;
  margin-top: 15px;

  @media (min-width: 768px) {
    width: ${({ total }) => total > 1 ? '768px' : '380px'};
    grid-template-columns: repeat(${({ total }) => total > 1 ? 2 : 1}, 1fr);
  }

  @media (max-width: 767px) {
    width: ${({ total }) => total > 1 ? '100%' : '380px'};
    grid-template-columns: repeat(${({ total }) => total > 1 ? 2 : 1}, 1fr);
  }

  @media (max-width: 480px) {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
`

export const SmallImage = styled.img`
  width: 24px;
  height: 24px;
`

export const InfoText = styled.p`
  font-size: 14px;
  text-align: center;
  line-height: 18px;
  margin: 0px;
`

export const Title = styled(InfoText)`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
`

export const SubTitle = styled(Title)`
  font-size: 14px;
  margin-bottom: 0px;
`

export const LinkText = styled.a`
  color: #35A7FF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  gap: 3px;

  &:hover {
    color: #2875E2;
  }
`

export const WarningText = styled(InfoText)`
  color: #FF3939;
  margin-top: 2px;
`

export const ErrorContainer = styled.div`
  padding: 9px;
  margin-top: 15px;
  width: 100%;
  border-radius: 15px;
  align-items: center;
  background-color: #FF3939;
`

export const Button = styled.button.attrs ({
  type: 'button'
})`
  background-color: #252525;
  color: #F7F7F7;
  height: 40px;
  padding: 0px 20px;
  border-radius: 15px;
  line-height: 16px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: none;

  &:hover {
    background-color: #ffC100;
    color: #FFFFFF;
  }
`

export const DangerButton = styled(Button)`
  background-color: #FF3939;

  &:hover {
    background-color: #FF2020;
    color: #FFFFFF;
  }
`

export const SmallButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-bottom: 10px;
  border: 0px;
  gap: 5px;

  &:hover {
    color: #FFC100;
  }
`
