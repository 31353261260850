import React from 'react'

import {
  SubContainer,
  ServicesGrid,
  Button,
  SubTitle
} from '../styles'

function CategoriesForm ({
  services = [],
  serviceCategories = [],
  onCategorySelected: handleCategorySelected = () => null
}) {
  const businessServiceCategories = serviceCategories.filter (category => (
    services.some (service => service.category.id === category.id)
  ))

  return (
    <SubContainer>
      <SubTitle>Escolha a categoria de serviço</SubTitle>
      <ServicesGrid>
        {businessServiceCategories.map ((category, index) => (
          <Button key={index} onClick={() => handleCategorySelected (category)}>
            {category.name}
          </Button>
        ))}
      </ServicesGrid>
    </SubContainer>
  )
}

export default CategoriesForm
