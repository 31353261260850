import React, { useState } from 'react'
import cutileiApi from '../../services/cutileiApi'
import ScheduleService from '../../services/Schedule'
import AlertDialog from '../AlertDialog'
import { ReactComponent as ButtonLoading } from '../../icons/loading.svg'
import { DateTime } from 'luxon'

import {
  Container,
  Header,
  DateContainer,
  Row,
  Title,
  SubTitle,
  InfoText,
  StatusText,
  Button,
  DangerButton
} from './styles'

function CustomerSchedule ({
  data: schedule,
  onEdit: handleEdit,
  onDelete: handleDelete,
  showActions = false,
  containerStyle
}) {
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [deleting, setDeleting] = useState (false)
  const { customer } = schedule
  const scheduleDateTime = DateTime.fromFormat (`${schedule.date} ${schedule.time}`, 'yyyy-MM-dd H:mm')
  const professionalName = schedule.professional.nickname ?? schedule.professional.name.split (' ')[0]

  const isCompleted = schedule.validated || (
    DateTime.now () >= scheduleDateTime.plus ({minutes: (schedule.max_delay ?? 0) + 1})
  )

  const cancelSchedule = async () => {
    setDeleting (true)
    try {
      await cutileiApi.delete (`/customers/${customer.id}/schedules/${schedule.id}`)
      setDeleting (false)
      handleDelete (schedule)
    } catch (error) {
      setDeleting (false)
      console.log (error)
    }
  }

  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)

  return (
    <Container scheduleValidated={schedule.validated} style={containerStyle}>
      <Header>
        <DateContainer>
          <SubTitle>
            {scheduleDateTime.setLocale ('pt-BR').toFormat ('cccc')}
          </SubTitle>
          <Title>
            {scheduleDateTime.toFormat ('dd/MM/yyyy')}
          </Title>
        </DateContainer>
        <Title>
          {schedule.time}
        </Title>
      </Header>
      <Row>
        <SubTitle>Salão:</SubTitle>
        <InfoText>{schedule.business.name}</InfoText>
      </Row>
      <Row>
        <SubTitle>Serviço:</SubTitle>
        <InfoText>{schedule.service.name}</InfoText>
      </Row>
      <Row>
        <SubTitle>Profissional:</SubTitle>
        <InfoText>{professionalName}</InfoText>
      </Row>
      <Row>
        <SubTitle>Duração:</SubTitle>
        <InfoText>
          {ScheduleService.getFormattedDuration (
            schedule.duration ?? schedule.service.duration
          )}
        </InfoText>
      </Row>
      <Row>
        <SubTitle>Tolerância:</SubTitle>
        <InfoText>{schedule.max_delay ?? schedule.service.max_delay}m</InfoText>
      </Row>
      {isCompleted ? (
        <StatusText validated={schedule.validated}>
          {schedule.validated ? 'Realizado': 'Não realizado'}
        </StatusText>
      ) : showActions && (
        <Row>
          <Button onClick={() => handleEdit (schedule)}>
            Alterar
          </Button>
          <DangerButton onClick={toggleAlertDialog}>
            {deleting ? <ButtonLoading/> : 'Cancelar'}
          </DangerButton>
        </Row>
      )}
      <AlertDialog
        visible={showAlertDialog}
        title='Atenção!'
        message='Deseja realmente cancelar este agendamento?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={cancelSchedule}
        onClose={toggleAlertDialog}
        dangerous
      />
    </Container>
  )
}

export default CustomerSchedule
