import React from 'react'

import {
  Container,
  LoadingContainer,
  LoadedIndicator,
  InfoText
} from './styles'

function LoadingBar ({
  currentValue,
  totalValue,
  valueUnit = ''
}) {
  return (
    <Container>
      <LoadingContainer>
        <LoadedIndicator currentValue={currentValue} totalValue={totalValue}/>
      </LoadingContainer>
      <InfoText>{currentValue} / {totalValue} {valueUnit}</InfoText>
    </Container>
  )
}

export default LoadingBar
