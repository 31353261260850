import React, { useState, useEffect } from 'react'
import ClearableInput from '../../../components/Inputs/ClearableInput'

import {
  SubContainer,
  Row,
  FilterColumn,
  ServicesGrid,
  Button,
  SubTitle
} from '../styles'

function ServicesForm ({
  schedule = {},
  services = [],
  onServiceSelected: handleServiceSelected = () => null
}) {
  const { service_category } = schedule
  const [filteredServices, setFilteredServices] = useState ([])
  const [name, setName] = useState ('')

  useEffect (() => {
    filterServices ()
  }, [name])

  const filterServices = () => {
    setFilteredServices (services.filter (service => (
      service.name.toLowerCase ().includes (name.toLowerCase ())
      && service.category.id === service_category.id
    )))
  }

  return (
    <SubContainer>
      <SubTitle>Escolha o serviço</SubTitle>
      <Row style={{marginTop: 10}}>
        <FilterColumn>
          <ClearableInput
            placeholder='Pesquisar por nome'
            value={name}
            onChange={e => setName (e.target.value)}
            onClear={() => setName ('')}
            inputStyle={{flex: 1, boxShadow: '0px 1px 10px -6px', paddingInline: 12}}
          />
        </FilterColumn>
      </Row>
      <ServicesGrid>
        {filteredServices.map ((service, index) => (
          <Button key={index} onClick={() => handleServiceSelected (service)}>
            {service.name}
          </Button>
        ))}
      </ServicesGrid>
    </SubContainer>
  )
}

export default ServicesForm
