import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../services/cutileiApi'
import Phone from '../../services/Phone'
import Address from '../../services/Address'
import ServicesForm from './ServicesForm'
import CategoriesForm from './CategoriesForm'
import TimeTablesForm from './TimeTablesForm'
import ProfessionalsForm from './ProfessionalsForm'
import Confirmation from './Confirmation'
import { IoLocationSharp } from 'react-icons/io5'
import CutileiLogo from '../../icons/logo-cutilei-simple.png'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  Container,
  Column,
  Image,
  SmallImage,
  Form,
  Row,
  LogoRow,
  TextRow,
  StepRow,
  StepContainer,
  StepCircle,
  StepLabel,
  StepLine,
  // RatingContainer,
  Title,
  SubTitle,
  InfoText,
  LinkText,
  Block,
  Circle,
  SmallButton,
} from './styles'

function ScheduleLink ({ history, match }) {
  const { business_id, schedule_id } = match.params
  const customer = history.location.state?.customer

  const steps = [
    {number: 0, label: 'Categorias'},
    {number: 1, label: 'Serviço'},
    {number: 2, label: 'Horário'},
    {number: 3, label: 'Profissional'},
    {number: 4, label: 'Confirmar'}
  ]

  const [loading, setLoading] = useState (true)
  const [business, setBusiness] = useState (null)
  const [services, setServices] = useState ([])
  const [serviceCategories, setServiceCategories] = useState ([])
  const [currentStep, setCurrentStep] = useState (steps[0])
  const [schedule, setSchedule] = useState ({})
  const address = business?.addresses[0] ? Address.formatAddress (business?.addresses[0]) : ''
  const addressUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      let scheduleData = schedule
      const { data: business } = await cutileiApi.get (`/businesses/${business_id}`)
      const { data: serviceCategories } = await cutileiApi.get ('/service_categories')
      const { data: services } = await cutileiApi.get (
        `/businesses/${business_id}/services?cutilei_services=false&accepts_online=true`
      )
      
      if (schedule_id) {
        const { data } = await cutileiApi.get (`/schedules/${schedule_id}`)
        scheduleData = data
      }
      
      setSchedule ({...scheduleData, business, ...!!customer && {customer}})
      setBusiness (business)
      setServices (services)
      setServiceCategories (serviceCategories)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const handleCategorySelected = category => {
    setSchedule ({...schedule, service_category: category})
    nextStep ()
  }

  const handleServiceSelected = service => {
    setSchedule ({...schedule, service: service})
    nextStep ()
  }

  const handleDateTimeSelected = (date, time) => {
    setSchedule ({...schedule, date: DateTime.fromJSDate (date).toISODate (), time})
    nextStep ()
  }

  const handleProfessionalSelected = professional => {
    setSchedule ({...schedule, professional})
    nextStep ()
  }

  const handleScheduleCreated = schedule => {
    history.push ({
      pathname: `/schedule/success`,
      state: { schedule }
    })
  }

  const prevStep = () => setCurrentStep (steps[currentStep.number - 1])
  const nextStep = () => setCurrentStep (steps[currentStep.number + 1])

  return loading ? null : (
    <Container>
      <LogoRow>
        <SmallImage src={CutileiLogo} alt='Cutilei'/>
        <Title style={{margin: 0}}>Cutilei</Title>
      </LogoRow>
      <Row style={{alignItems: 'center'}}>
        <Column>
          <Row style={{position: 'relative', width: 'auto'}}>
            <Image src={business.logo}/>
            {/* {business?.average_rating > 0 && (
              <RatingContainer>
                <FA.FaStar size={14} color='#FFC100'/>
                <SubTitle>
                  {business?.average_rating}
                </SubTitle>
              </RatingContainer>
            )} */}
          </Row>
        </Column>
        <Column style={{alignItems: 'flex-start'}}>
          <Title>{business.name}</Title>
          <Block>
            <TextRow>
              <LinkText
                href={`https://api.whatsapp.com/send?phone=55${business.cellphone}`}
                style={{color: '#44CF6C', fontWeight: 'bold'}}
              >
                <FA.FaWhatsapp size={18} color='#44CF6C'/>
                {Phone.formatPhone (business.cellphone)}
              </LinkText>
            </TextRow>
          </Block>
          {business.social_medias.map (socialMedia => (
            <Block key={socialMedia.id}>
              <LinkText href={socialMedia.link}>
                {socialMedia.name.toLowerCase () === 'instagram' ? (
                  <FA.FaInstagram size={18} color='#252525'/>
                ) : (
                  <FA.FaFacebook size={18} color='#0866FF'/>
                )}
                <SubTitle>{socialMedia.name}</SubTitle>
              </LinkText>
            </Block>
          ))}
          <Block>
            <LinkText href={addressUrl} target='_blank' rel='noopener noreferrer'>
              <IoLocationSharp size={20} color='#FF3939' style={{marginLeft: -1, marginRight: -2}}/>
              <SubTitle>Como chegar</SubTitle>
            </LinkText>
          </Block>
        </Column>
      </Row>
      <StepRow>
        {steps.map ((step, index) => (
          <StepContainer key={index}>
            <StepCircle step={step} currentStep={currentStep}>
              {currentStep.number > step.number ? (
                <FA.FaCheck color='#FFFFFF' size={14}/>
              ) : (
                <InfoText>{step.number + 1}</InfoText>
              )}
            </StepCircle>
            <StepLabel>{step.label}</StepLabel>
          </StepContainer>
        ))}
        <StepLine currentStep={currentStep.number}/>
      </StepRow>
      {currentStep.number > 0 && (
        <SmallButton onClick={prevStep}>
          <Circle>
            <FA.FaChevronLeft color='#FFFFFF' size={14} style={{marginLeft: -2}}/>
          </Circle>
          <SubTitle>Voltar</SubTitle>
        </SmallButton>
      )}
      {currentStep.number === 0 && (
        <CategoriesForm
          services={services}
          serviceCategories={serviceCategories}
          onCategorySelected={handleCategorySelected}
        />
      )}
      {currentStep.number === 1 && (
        <ServicesForm
          schedule={schedule}
          services={services}
          onServiceSelected={handleServiceSelected}
        />
      )}
      {currentStep.number === 2 && (
        <TimeTablesForm
          schedule={schedule}
          onDateTimeSelected={handleDateTimeSelected}
        />
      )}
      {currentStep.number === 3 && (
        <ProfessionalsForm
          schedule={schedule}
          onProfessionalSelected={handleProfessionalSelected}
        />
      )}
      {currentStep.number === 4 && (
        <Confirmation
          schedule={schedule}
          onConfirm={handleScheduleCreated}
        />
      )}
    </Container>
  )
}

export default withRouter (ScheduleLink)
