import React from 'react'
import { withRouter } from 'react-router-dom'
import CustomerSchedule from '../../../components/CustomerSchedule'
import CutileiLogo from '../../../icons/logo-cutilei-simple.png'

import {
  Container,
  SubContainer,
  FormField,
  LogoRow,
  SmallImage,
  Form,
  Title,
  InfoText,
  Button
} from '../styles'

function Confirmed ({ history }) {
  const schedule = history.location.state?.schedule

  const createNewSchedule = () => {
    history.replace ({
      pathname: `/businesses/${schedule.business.id}/schedule`,
      state: { 
        customer: schedule?.customer
      }
    })
  }

  const goToSchedules = () => {
    history.replace (`/customers/${schedule.customer.id}/schedules`)
  }

  return (
    <Container style={{padding: 5}}>
      <SubContainer>
        <Form style={{boxShadow: 'none', backgroundColor: '#FFFFFF'}}>
          <LogoRow>
            <SmallImage src={CutileiLogo} alt='Cutilei'/>
            <Title style={{margin: 0}}>Cutilei</Title>
          </LogoRow>
          <Title style={{color: '#44CF6C', marginBottom: 12}}>
            Agendado com sucesso!
          </Title>
          <InfoText>
            Confira os detalhes do seu agendamento:
          </InfoText>
          <FormField>
            <CustomerSchedule data={schedule} containerStyle={{width: '100%', marginBlock: 10}}/>
          </FormField>
          <InfoText>
            Você pode fazer um novo agendamento neste mesmo salão ou conferir os seus agendamentos futuros abaixo
          </InfoText>
          <SubContainer style={{marginTop: 15, gap: 10}}>
            <Button onClick={createNewSchedule} style={{width: 220}}>
              Marcar outro agendamento
            </Button>
            <Button onClick={goToSchedules} style={{width: 220}}>
              Ver meus agendamentos
            </Button>
          </SubContainer>
        </Form>
      </SubContainer>
    </Container>
  )
}

export default withRouter(Confirmed)
