import * as Yup from 'yup'

const editProductValidator = Yup.object ({
  id: Yup.string (),
  nickname: Yup.string (),
  
  use_in_service: Yup
    .object ()
    .nullable ()
    .required ('Selecione a finalidade'),

  content_unit: Yup
    .object ()
    .nullable ()
    .required ('Selecione a unidade de medida'),

  price: Yup.number ().when (['use_in_service'], (use_in_service, schema) => {
    if (!use_in_service?.value)
      return schema.positive ('Insira o preço')
    return schema
  }),
    
  cost: Yup.number (),
  total_stock: Yup.number (),
  total_content: Yup.number ()
})

export default editProductValidator
