import { useState } from 'react'
import { FaRegCopy, FaCheck } from 'react-icons/fa'
import { Button } from './styles'

export default function CopyButton ({ text }) {
  const [copied, setCopied] = useState (false)

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText (text)
      setCopied (true)
      setTimeout (() => setCopied (false), 2000)
    } catch (error) {
      console.error ('Falha ao copiar!', error)
    }
  }

  return (
    <Button onClick={copyToClipboard}>
      {copied ? <FaCheck color='#FFC100'/> : <FaRegCopy color='#FFC100'/>}
      {copied ? 'Copiado!' : 'Copiar link'}
    </Button>
  )
}
