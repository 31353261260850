import * as Yup from 'yup'

const createServiceValidator = Yup.object ({
  name: Yup
    .string ()
    .required ('Insira um nome')
    .max (48, 'O nome deve ter até 48 caracteres'),

  category: Yup
    .object ()
    .nullable ()
    .required ('Selecione a categoria'),

  description: Yup
    .string ()
    .max (256, 'A descrição deve ter até 256 caracteres'),

  duration: Yup.number (),
  max_delay: Yup.number (),
  price: Yup.number ().positive ('Insira o preço'),
  comission_percentage: Yup.number (),
  professional_comission: Yup.number (),
  business_comission: Yup.number (),

  products: Yup.array ().of (Yup.object ().shape ({
    id: Yup.string ().required ('Selecione o produto'),
    name: Yup.string ().required ('Selecione o produto'),
    quantity_used: Yup.number ()
  }))
})

export default createServiceValidator
